import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;

  h5 { 
    padding: 1rem 0;
  }

  hr {
    margin-top: 2rem;
  }

  .MuiAutocomplete-root {
    max-width: initial;
    width: 100%;
    margin: 0;

    > div {
      margin: 0;
    }

    .MuiFormControl-root {
      max-width: 100%;
    }
  }
`;

export const FormWrapper = styled.form`
  display: grid;
  grid-template-areas: 'label label name name' 'botao botao . .';
  grid-gap: 1rem;
  max-width: 55.18rem;

  > button {
    margin-top: 1rem;
    grid-area: botao
  }

  @media (max-width: 880px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;