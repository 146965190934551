import { OutlinedInput } from '@material-ui/core';
import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const Input = styled(OutlinedInput)`
  padding: 0 8px;
`;

export const PlayerArea = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  gap: 10px;
  width: 100%; 
  height: 100%; 

  .datepicker-wrapper {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      cursor: pointer;
    }

    .input-icon {
      //width: 20px;
    }
  }
`;

export const CardsContainer = styled.section`
  width: 100%; 
  height: 100%;
  padding: 0 10px; 
  display: flex; 
  flex-direction: column; 
  gap: 10px;
  overflow-y: auto;
  padding-bottom: 85px;
`;

export const Icon = styled.img`
  :hover {
    cursor: pointer;
    fill: #fff;
  }
`;

export const PlayerControllerContainer = styled.div<{ isLoadingNewContent: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  
  .controller {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 40px;
  }

  button {
    width: 50px; 
    height: 30px;
    border: 1px solid lightgray;
    border-radius: 4px;
    font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    box-shadow: 0.5px 0.5px 1px gray;
    cursor: pointer;
  }
`;

export const TimeReference = styled.h1`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-family: monospace;
  font-size: 20px;
  color: #585858;
`;

export const SearchWrapper = styled.section`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  padding: 20px 10px;

  > * {
    width: 95%;
  }

  input {
    width: 100%;

    ::placeholder {
      color: #333333;
    }
  }

  @media (width < 900px) {
   padding-top: 80px; 
  }
`;

export const TimelineWrapper = styled.div<{ isLoadingNewContent: boolean }>`
  pointer-events: ${props => props.isLoadingNewContent ? 'none' : 'all'};
  position: relative;

  .loader-wrapper {
    display: ${props => props.isLoadingNewContent ? 'initial' : 'none'};
    z-index: 9999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .loader {
    border: 8px solid transparent; 
    border-top: 8px solid #2c66f1;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  > div:last-child { //Timeline
    filter: ${props => props.isLoadingNewContent ? 'blur(2px)' : 'blur(0)'};
  }

  @keyframes spin {
    0% { 
      transform: rotate(0deg);
    }
    100% { 
      transform: rotate(360deg); 
    }
  }
`;

export const TimeControllerContainer = styled.div`
  display: flex; 
  position: relative; 
  width: 100%; 
  max-width: 800px;

  > .zoom-controller {
    position: absolute;
    display: flex;
    gap: 10px; 
    right: 0;

    button {
      width: 40px;
      height: 30px;
      box-shadow: 0.5px 0.5px 1px gray;
      border-radius: 4px;
      border: 1px solid lightgray;
      cursor: pointer;
    }
  }
`;

export const IconsWrapper = styled.section<{ activeBorder: boolean }>`
  position: relative;
  display: flex; 
  align-items: center; 
  gap: .5rem;
  border: 1px solid ${props => props.activeBorder ? props.theme.palette.primary.main : '#80808069' };
  padding: 8px;

  .recorte-label {
    position: absolute;
    top: -12px;
    background-color: #eeeeee;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 300;
    font-size: 13px;
    padding: 0 5px;
  }

  .recorte-icon {
    font-size: 16px;
  }

  svg {
    pointer-events: none;
  }
`;