import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { deleteLocalStorageData, getLocalStorageData } from './auth';

type SessionStateProps = {
  username: string;
  token: string;
  refresh: string;
};

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (config.url?.includes('/auth')) return config;

  const storagedData: SessionStateProps = getLocalStorageData();

  if (storagedData) {
    config.headers = {
      Authorization: `Bearer ${storagedData.token}`,
    };
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  if (
    !error.response?.config.url?.includes('/auth/token') &&
    (error.response?.status === 403 || error.response?.status === 401)
  ) {
    deleteLocalStorageData();
  }
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  //Se a URL não bater e o erro for 403 || 401, ele remove a sessão e retorna um erro;
  //caso contrário, rotina de logout
  if (
    !error.response?.config.url?.includes('/auth/token') &&
    (error.response?.status === 403 || error.response?.status === 401)
  ) {
    deleteLocalStorageData();

    if (window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
