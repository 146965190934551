import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';

type ButtonProps = {
  typecolor: 'blueButton' | 'exitButton';
};

export const StyledButton = styled(Button)<ButtonProps>`
  && {
    background-color: #0d3efb;
    border-bottom: #092cb2 2px solid;
    color: #fff;
    ${({ disabled }) => disabled && 'border-bottom : 2px solid #A2A7AC;'}

    :hover {
      background-color: #3c2ee3;
      border-bottom: 2px solid #3d1ff3;
    }
    :active {
      background-color: #223b6b;
      border-bottom: 2px solid #222b6b;
    }
  }
`;

export const OutlinedButton = styled(Button)<ButtonProps>`
  && {
    color: #a2a7ac;

    ${({ disabled }) =>
      disabled &&
      'background-color: #FFFFFF; color: #A2A7AC; border: #A2A7AC 1px solid'}

    :hover {
      color: #f45248;
      border: #f45248 1px solid;
      background-color: #fff;
    }
  }
`;
