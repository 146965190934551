import { CardContent, Divider, IconButton, Typography, makeStyles } from "@material-ui/core";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { CardContainer, CardRoot } from "./styles";

type Props = {
  label: string;
  text: string;
  isFocused: boolean;
  setCurrentClip: (clip: any) => void;
}

const AudioCard = ({ label, text, setCurrentClip, isFocused, ...rest }: Props) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    iconBtn: {
      width: 50,
      height: 50,
    },
    playIcon: {
      height: 38,
      width: 38,
      color: 'red',
      fill: theme.palette.primary.light ?? 'gray' //talvez redefinir para o segundo tema default
    },
    title: {
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.72)',
    },
    divider: {
      backgroundColor: theme.palette.primary.light ?? 'lightgray'
    }
  }));

  const classes = useStyles();

  function formatClipDataAndReturnToParent() {
    const clip = {
      playerLabel: `${label} - ${text}`,
      ...rest
    }

    setCurrentClip(clip);
  }

  return (
    <CardRoot $isFocused={isFocused}>
      <div className={classes.root} onClick={formatClipDataAndReturnToParent}>
        <CardContainer>
          <CardContent>
            <Typography className={classes.title} component="h5" variant="h5">
              {label}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" color="textSecondary">
              {text}
            </Typography>
          </CardContent>

          <IconButton className={classes.iconBtn} aria-label="play/pause" onClick={formatClipDataAndReturnToParent}>
            <PlayArrowIcon className={classes.playIcon} />
          </IconButton>
        </CardContainer>
      </div>
    </CardRoot>
  )
};

export default AudioCard;