import { Switch } from 'react-router-dom';
import DrawerMenu from './components/drawer-menu';
import Routes from './routes';

export function AdminPage() {
  return (
    <DrawerMenu>
      <Switch>
        <Routes />
      </Switch>
    </DrawerMenu>
  );
}
