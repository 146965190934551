import React from 'react';

import { OutlinedButton, StyledButton } from './styles';

type ButtonProps = {
  text: string;
  handleClick?: () => void;
  typecolor: 'blueButton' | 'exitButton';
  variant?: 'text' | 'outlined' | 'contained';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  disableRipple?: boolean;
  children?: string;
  styles?: Object;
};

const DefaultButton = ({
  typecolor,
  variant,
  type,
  disableRipple,
  disabled,
  text,
  styles,
  handleClick,
}: ButtonProps) => {
  if (typecolor === 'blueButton') {
    //Return default button with variant contained
    return (
      <StyledButton
        typecolor={typecolor}
        variant={variant}
        type={type}
        disableRipple={disableRipple}
        disabled={disabled}
        onClick={handleClick}
        style={styles}
      >
        {text}
      </StyledButton>
    );
  } else {
    return (
      //return Default button with variant outlined
      <OutlinedButton
        typecolor={typecolor}
        variant={variant}
        type={type}
        disableRipple={disableRipple}
        disabled={disabled}
        onClick={handleClick}
        style={styles}
      >
        {text}
      </OutlinedButton>
    );
  }
};

export default DefaultButton;
