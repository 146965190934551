import styled from 'styled-components';

import { Container, Grid, Card, Typography } from '@material-ui/core/';

export const StyledContainer = styled(Container)``;

export const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${(props) => (props.container ? 'height: 100vh;' : 'height: 40vh')}
`;

export const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  position: relative;
  border-radius: 10px !important;
`;

export const StyledForm = styled.form``;

export const StyledTypography = styled(Typography)`
  cursor: pointer;
  :hover {
    color: #3c2ee3;
  }
`;

export const GloboIcon = styled.img`
  position: absolute;
  top: 23px;
  width: 295px;
  height: 120px;
`;
