import React, { ReactNode, useEffect, useState } from 'react';

import { Drawer, Typography, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory, useLocation } from 'react-router-dom';
import {
  deleteFromStorage,
  retrieveFromStorage,
  saveToStorage,
} from '../../../utils/local-storage-manager';
import { Container, ListButton, ListContainer, SideBar } from './drawer.styles';

type SidebarProps = {
  label: string;
  name: string;
  pathname: string;
  state?: any;
};

interface LastVisitedRoute extends Location{
  lastVisitedRoute: string;
}

export default function TemporaryDrawer({ children }: { children: ReactNode }) {
  const location = useLocation<LastVisitedRoute>();
  const history = useHistory();
  const MatchQuery = useMediaQuery('(max-width: 599px)');
  const drawerAnchor = MatchQuery ? 'top' : 'left';
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedMenuOption, setSelectedMenuOption] = useState('');

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsOpen(open);
    };

  const sidebarOptions: Array<SidebarProps> = [
    {
      label: 'Listar usuários',
      name: 'show-users',
      pathname: '/admin/users',
      state: location.state,
    },
    {
      label: 'Criar novo usuário',
      name: 'create-user',
      pathname: '/admin/create-user',
    },
    {
      label: 'Criar nova praça',
      name: 'create-plaza',
      pathname: '/admin/create-plaza',
    },
    {
      label: 'Criar novo grupo',
      name: 'create-group',
      pathname: '/admin/create-group',
    },
  ];

  const handleNavigation = (path: string, nameToStore: string) => {
    setSelectedMenuOption(nameToStore);
    saveToStorage('currentPage', nameToStore);
    history.push(path);
  };

  const handleGoBackToLastVisitedRoute = () => {
    const hasHistoryToReturn = location?.state?.lastVisitedRoute ?? JSON.parse(retrieveFromStorage('lastVisitedRoute') as string);
    history.push(hasHistoryToReturn ?? '/video');
    deleteFromStorage('lastVisitedRoute');
  }

  useEffect(() => {
    const currentSelectedSidebarOption = retrieveFromStorage('currentPage');
    if (currentSelectedSidebarOption) {
      setSelectedMenuOption(currentSelectedSidebarOption);
    }
  }, []);

  return (
    <Container $isMobile={MatchQuery}>
      <SideBar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </SideBar>
      <Drawer anchor={drawerAnchor} open={isOpen} onClose={toggleDrawer(false)}>
        <ListContainer
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          $isMobile={MatchQuery}
        >
          <List>
            {sidebarOptions.map((item) => (
              <ListItem key={item.name}>
                <ListButton
                  $currentPage={item.name === selectedMenuOption}
                  onClick={() => handleNavigation(item.pathname, item.name)}
                >
                  <Typography variant="button">{item.label}</Typography>
                </ListButton>
              </ListItem>
            ))}
            <ListItem>
              <ListButton
                onClick={handleGoBackToLastVisitedRoute}
                $backButton
              >
              <Typography variant="button">Sair</Typography>
            </ListButton>
          </ListItem>
        </List>
      </ListContainer>
      </Drawer>
      {children}
    </Container>
  );
}
