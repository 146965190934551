import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import SnackBar from '../pages/admin/layout/components/Snackback';
import { FeedbackType, SnackbarProps } from './types';

const SnackBarContext = createContext({} as SnackbarProps);

export const SnackBarProvider = ({ children }: { children?: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<FeedbackType>("info");
  const [message, setMessage] = useState('');

  const snackbar = useMemo(
    () => ({
      status: isOpen,
      setStatus: setIsOpen,
      type,
      setType,
      message,
      setMessage
    }),
    [isOpen, type, message]
  );

  return (
    <SnackBarContext.Provider value={snackbar}>
      {children}
      <SnackBar
        open={snackbar.status}
        setOpen={snackbar.setStatus}
        feedbackType={snackbar.type}
      >
        {snackbar.message}
      </SnackBar>
    </SnackBarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackBarContext);

export default SnackBarProvider;
