import { Redirect, Route, Switch } from 'react-router-dom';

import { RoutesProp } from '../../../routes/types';

import { DialogProvider } from '../../../contexts/dialog';
import StoragedUserProvider from '../../../contexts/user';
import { isAuthenticated } from '../../../utils/auth';
import { CreateGroup } from '../layout/create-group';
import { CreatePraca } from '../layout/create-praca';
import { CreateUser } from '../layout/create-user/create-user';
import { EditUser } from '../layout/edit-user/edit-user';
import { UsersList } from '../layout/users';

const handleValidateAuthSession = (component: JSX.Element) => {
  return isAuthenticated() ? component : <Redirect to="/login" />;
};

const routes: RoutesProp[] = [
  {
    id: 1,
    component: () => handleValidateAuthSession(<UsersList />),
    otherConfigs: {
      path: '/admin/users',
      exact: true,
    },
  },
  {
    id: 2,
    component: () => handleValidateAuthSession(<CreateUser />),
    otherConfigs: {
      path: '/admin/create-user',
      exact: true,
    },
  },
  {
    id: 2,
    component: () => handleValidateAuthSession(<EditUser />),
    otherConfigs: {
      path: '/admin/edit-user',
      exact: true,
    },
  },
  {
    id: 3,
    component: () => handleValidateAuthSession(<CreatePraca />),
    otherConfigs: {
      path: '/admin/create-plaza',
      exact: true,
    },
  },
  {
    id: 4,
    component: () =>
      isAuthenticated() ? <CreateGroup /> : <Redirect to="/login" />,
    otherConfigs: {
      path: '/admin/create-group',
      exact: true,
    },
  },
];

const Routes = () => {
  return (
    <StoragedUserProvider>
      <DialogProvider>
        <Switch>
          {routes.map(({ id, component, otherConfigs }: RoutesProp) => (
            <Route key={id} component={component} {...otherConfigs} />
            ))}
          </Switch>
      </DialogProvider>
    </StoragedUserProvider>
  );
};
export default Routes;
