import { PaperProps, Table, TableContainer } from '@material-ui/core';
import styled from "styled-components";

type TableProp = {
  component: (props: PaperProps) => JSX.Element;
}

export const StyledTableContainer = styled(TableContainer) <TableProp>`
  background-color: lightgray;
  margin-top: .5rem;
  animation: fadeInSlide 0.7s ease;

  > section {
    padding: 10px 15px 20px 15px;
  }

  && {
    @media(min-width: 1400px){
      max-width: 99%;
    }
    @media(max-width: 1199px){
      max-width: 100%;
    }
  }

  @keyframes fadeInSlide {
    from {
      opacity: 0;
      margin-top: -200px;
    }

    to {
      opacity: 1;
      margin-top: .5rem;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    tr:nth-child(even){
      background-color: #f2f2f2;
    }

    th {
      user-select: none;
    }

    td {
      svg {
        cursor: pointer;
      }
    }

    @media(max-width: 1199px){
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr, td {
        :last-child {
          border-bottom: none;
        }
      }

      tr {
        border-bottom: 3px solid #ddd;
        display: block;
      }
  
      td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 1.1rem;
        text-align: right;

        ::before {
          content: attr(data-label);
          text-transform: uppercase;
          font-weight: bold;
          float: left;
          font-size: 14px;
          color: #333333;
        }
      }
    }

    @media(max-width: 599px){
      td {
        font-size: 0.875rem;
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;

  
  button {
    display: grid;
    place-items: center;
    padding: .5rem .3rem;
    background-color: transparent;
    border: 2px solid #aaaaaa;
    border-radius: 3px;
    cursor: pointer;

    svg {
      fill: #aaaaaa;
    }

    :hover,:focus,:active,:focus-visible {
      border-color: #0D3EFB;

      svg {
        fill: #0D3EFB;
      }
    }
  }
`;

export const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 2rem;
  
  div {
    position: absolute;
    border: 4px solid #2da4fc;
    opacity: 1;
    border-radius: 50%;
    animation: 'lds-ripple' 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
      border-color: #1e49c6;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
      border-color: #20B9FC;
    }
  }
`;