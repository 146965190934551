import { useEffect, useState } from 'react';
import { ReactComponent as PersonIcon } from '../../assets/person.svg';

import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { Check, ExpandLess, ExpandMore } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { UserData } from '../../pages/player/types';
import axios from '../../services/api';
import { saveToStorage } from '../../utils/local-storage-manager';
import * as S from './styles';

type Pathnames = '/audio' | '/video';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userData, setUserData] = useState({} as UserData);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(true);

  const Auth = useAuth();
  const history = useHistory();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsUserMenuOpen(true);
  };

  const handleCloseMenu = () => { 
    setIsUserMenuOpen(false);
    setAnchorEl(null);
  };

  const handleGoToAdminPage = (pathname: Pathnames) => {
    history.push({ pathname: '/admin/users', state: { username: userData?.name, lastVisitedRoute: pathname } });
    saveToStorage('lastVisitedRoute', pathname);
  };

  const handleGoTo = (pathname: Pathnames) => {
    history.push({ pathname, state: pathname });
  }

  const handleLogout = () => Auth.logout();

  const handleExpandSubmenu = () => {
    setIsSubMenuOpen(prev => !prev);
  }

  useEffect(() => {
    const handleGetUserByUsername = async () => {
      const StoredUserData = Auth?.sessionData;

      try {
        const response = await axios.get(`/users/${StoredUserData?.username}`);
        setUserData(response?.data);
      } catch (err) {
        console.error(err);
        Auth.logout();
      }
    };
    
    handleGetUserByUsername();
  }, [Auth]);

  const handleRenderCheck = (pathname: Pathnames) => {
    return window.location.pathname.includes(pathname) && <Check color='primary' />;
  }

  return (
    <S.UserIconWrapper onClick={handleClick} isMenuOpen={isUserMenuOpen}>
      <PersonIcon />
      
      <S.StyledMenu
      open={isUserMenuOpen}
      anchorEl={anchorEl}
      keepMounted
      onBlur={handleCloseMenu}
      style={{ marginTop: '45px' }}
    >
      <Typography
        style={{
          userSelect: 'none',
          textAlign: 'center',
          padding: '5px 0px 15px 0px',
        }}
      >
        {userData?.name}
      </Typography>
      {userData?.role === 'ROLE_ADMIN' && (
        <div>
          <ListItem onClick={() => handleGoToAdminPage(window.location.pathname as Pathnames)} button>
            <S.TextListItem primary="Configurações" />
          </ListItem>
          <ListItem button onClick={handleExpandSubmenu}>
          <ListItemText primary="Exibição" />
            { isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => handleGoTo('/video')}>
              <ListItemText primary="Vídeo" />
              <ListItemIcon>
                {handleRenderCheck('/video')}
              </ListItemIcon>
            </ListItem>
            <ListItem button onClick={() => handleGoTo('/audio')}>
              <ListItemText primary="Áudio" />
              <ListItemIcon>
                {handleRenderCheck('/audio')}
              </ListItemIcon>
              </ListItem>
            </List>
          </Collapse>
        </div>
      )}

        
        <ListItem>
          <hr style={{ width: '95%' }} />
        </ListItem>
        <S.MenuExitButton button onClick={handleLogout}>
          <ListItemText primary="Sair" />
        </S.MenuExitButton>
    </S.StyledMenu>
    </S.UserIconWrapper>
  )
};

export default UserMenu;