import { Button, MenuItem } from '@material-ui/core';
import { ChangeEvent, FormEvent, useEffect, useReducer } from 'react';
import { useSnackbar } from '../../../../contexts/snackbar';
import { axiosCallHandler } from '../../../../infra/axiosHelper';
import { FSetSnackbarProps } from '../../types';
import CustomAutoComplete from '../components/CustomAutoComplete';
import { ActionTypes, GroupReducer, initialState } from './reducer';
import * as S from './styles';

export function CreateGroup() {
  const snackbar = useSnackbar();
  const [data, dispatch] = useReducer(GroupReducer, initialState);

  function setSnackbar({ status, type, message }: FSetSnackbarProps) { 
    snackbar.setStatus(status);
    snackbar.setType(type);
    snackbar.setMessage(message);
  }

  function handleValidateTune(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (Number(value) < 0 || Number(value) > 999) return;
    if (!/^.{0,3}$/.test(value)) return;

    dispatch({
      type: ActionTypes.SET_TUNE,
      payload: event.target.value.replace(/[^\w\s]/gi, '').replace(/[a-z]/, ''),
    });
    dispatch({ type: ActionTypes.SET_NAME });
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = {
      name: data.name,
      clipping_uri: data.clipping_uri,
      tune: data.tune,
      enabled: data.enabled == "Sim" ? true : false,
      location_name: data.local.selected.name,
      channel_name: data.channel.selected.name,
      live_uri: data.live_uri,
    };

    if (
      data.tune === '' ||
      !data.local.selected.name ||
      !data.channel.selected.name
    ) {
      setSnackbar({
        status: true,
        type: 'error',
        message: 'Por favor, preencha os campos.',
      });

      return null;
    }

    await axiosCallHandler({
      method: 'POST',
      url: '/clipping-sources',
      data: formData,
    }).then(() => {
      axiosCallHandler({
        method: 'POST',
        url: '/groups',
        data: {
          clipping_source_name: formData.name,
        },
      }).then((res) => {
        setSnackbar({
          status: true,
          type: 'success',
          message: 'Alterações foram salvas.',
        });
      });
    });
  }

  function getClippingActivateStatus(locationName: string, channelName: string) {
    axiosCallHandler({
      method: 'GET',
      url: `/clipping-sources/locations/${locationName}/channels/${channelName}`
    }).then(res => {
      if(!res) {
        dispatch({ type: ActionTypes.SET_ENABLED, payload: "Sim" });
        return;
      }
   
      dispatch({ type: ActionTypes.SET_ENABLED, payload: res?.enable ? "Sim" : "Não" });
      setSnackbar({ status: true, type: 'info', message: 'Informações do grupo foram carregadas' })
  
    })
    .catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    (async function () {
      const localResponseData = await axiosCallHandler({
        method: 'GET',
        url: '/clipping-sources/locations',
      });

      dispatch({
        type: ActionTypes.SET_LOCAL_LIST,
        payload: localResponseData,
      });

      const channelResponseData = await axiosCallHandler({
        method: 'GET',
        url: '/clipping-sources/channels',
      });

      dispatch({
        type: ActionTypes.SET_CHANNEL_LIST,
        payload: channelResponseData,
      });
    })();
  }, []);

  return (
    <S.Container>
      <form onSubmit={handleSubmit}>
        <div>
          <CustomAutoComplete
            label="Local"
            options={data.local.list}
            value={data.local.selected}
            setValue={(payload) => {
              if(!payload) return;

              if(data.channel.selected.name.length > 0) {
                getClippingActivateStatus(payload.name, data?.channel?.selected?.name);
              }

              dispatch({ type: ActionTypes.SET_LOCAL_SELECTED, payload });
              dispatch({ type: ActionTypes.SET_NAME });
              dispatch({ type: ActionTypes.SET_CLIPPING_URI });
            }}
          />
          <CustomAutoComplete
            label="Canal"
            options={data.channel.list}
            value={data.channel.selected}
            setValue={(payload) => {
              if(!payload) return;

              if(data.local.selected.name.length > 0) {
                getClippingActivateStatus(data.local?.selected?.name, payload.name)
              }

              dispatch({ type: ActionTypes.SET_CHANNEL_SELECTED, payload });
              dispatch({ type: ActionTypes.SET_NAME });
              dispatch({ type: ActionTypes.SET_CLIPPING_URI });
            }}
          />
        </div>
        <hr />
        <div>
          <S.StyledTextField
            variant="outlined"
            label="Sintonia"
            value={data.tune}
            onInput={handleValidateTune}
          />
          <S.StyledTextField
            select
            label="Ativo"
            value={data.enabled}
            name="ativo"
            onChange={(e) =>
              dispatch({
                type: ActionTypes.SET_ENABLED,
                payload: e.target.value,
              })
            }
            variant="outlined"
          >
            <MenuItem value="Sim">Sim</MenuItem>
            <MenuItem value="Não">Não</MenuItem>
          </S.StyledTextField>
        </div>
        <hr />
        <section>
          <S.StyledTextField
            $isDisabled
            variant="outlined"
            label="URI do clipe"
            value={data.clipping_uri}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: ActionTypes.SET_CLIPPING_URI,
                payload: e.target.value,
              })
            }
          />
          <S.StyledTextField
            $isDisabled
            variant="outlined"
            label="URI da live"
            value={data.live_uri}
          />
        </section>
        <Button type="submit">Salvar</Button>
      </form>
    </S.Container>
  );
}
