import React, { useCallback, useEffect, useRef, useState } from 'react';
import Timeline from 'react-visjs-timeline';
import { TimelineOptions } from 'vis-timeline';

import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { useDebounce } from '../../hooks/useDebounce';
import PlayerLayout from "../layout/player-layout";
import Card from './components/card';
import Filter from './components/filter';
import { CardsContainer, Icon, IconsWrapper, PlayerArea, PlayerControllerContainer, SearchWrapper, TimeControllerContainer, TimelineWrapper, TimeReference } from './styles';

import { EventAvailable, EventBusy } from '@material-ui/icons';
import pauseIcon from '../../assets/pause_black.svg';
import playIcon from '../../assets/play_arrow.svg';
import zoomInIcon from '../../assets/zoom_in.svg';
import zoomOutIcon from '../../assets/zoom_out.svg';

import ReactHlsPlayer from 'react-hls-player';
import SimpleDialog from '../../components/Dialog';
import { useDialog } from '../../contexts/dialog';
import { axiosCallHandler } from '../../infra/axiosHelper';

import SearchIcon from '@material-ui/icons/Search';

import { ptBR } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BASE_URL } from "../../../src/services/download-media-api";
import axios from '../../services/api';
import { getLocalStorageData } from '../../utils/auth';
import { UserData } from '../player/types';
import AudioDialog from './components/dialog';
import './style.css';


type ClippingSourceShape = {
  name: string;
  channel_label: string;
  channel_name: string;
  location_label: string;
  location_name: string;
  enabled: boolean;
}

type AudioProps = {
  address: string;
  uuid: string;
}

type PromiseAudioProps = {
  data: AudioProps;
}

enum NavigateToFuture {
  '30_SECONDS' = 30000,
  '3_MINUTES' = 180000,
  '30_MINUTES' = 1800000,
  '1_DAY' = 86400000
}

enum NavigateToPast {
  '1_DAY' = -86400000,
  '30_MINUTES' = -1800000,
  '3_MINUTES' = -180000,
  '30_SECONDS' = -30000,
}

type CuttingIconProps = {
  icon: 'available' | 'busy',
  disabled?: boolean;
}

type ClipAreaProps = {
  id: number;
  type: string;
  content: string;
  start: Date | number | moment.Moment;
  end: Date | number | moment.Moment;
  editable: {
    updateTime: boolean;
  }
  className: string;
  selectable: boolean;
}

const dateTimePickerFormat = "YYYY-MM-DD HH:mm:ss"



const AudioPlayer = () => {
  const dialogContext = useDialog();

  const [currentClipToDisplay, setCurrentClipToDisplay] = useState<ClippingSourceShape & { playerLabel: string }>();
  const [clippings, setClippings] = useState<ClippingSourceShape[] | []>([]);
  const [filter, setFilter] = useState('');
  const [filteredClips, setFilteredClips] = useState<ClippingSourceShape[] | []>([]);
  const debouncedFilterTerm = useDebounce(filter, 600);
  const [audioUrl, setAudioUrl] = useState('');
  
  const [isLoadingNewMidia, setIsLoadingNewMedia] = useState(true);
  const trimUrlPattern = `${BASE_URL}/clips/{clipId}/trim/{seek}/{to}/download_audio`;
  const [selectedDate, setSelectedDate] = useState(moment().locale('pt-br').format(dateTimePickerFormat));
  
  //### Timeline
  const timelineRef = useRef<any>(null);

  const [isCriticalModalOpen, setIsCriticalModalOpen] = useState(false);
  const [searchConfirmationModal, setSearchConfirmationModal] = useState(false);
  const [changeSourceModal, setChangeSourceModal] = useState(false);

  const [currentZoomIndex, setCurrentZoomIndex] = useState(1); //Controla o index do zoom atual
  const currentZoomIndexRef = useRef(currentZoomIndex);
  currentZoomIndexRef.current = currentZoomIndex;

  const exhibitionWindowCurrentTime = useRef(new Date());
  const exhibitionWindowCurrentTimeTimeout = useRef<any>(undefined);

  //const [ZOOM] = useState([180, 30, 10, 3]);
  const EXHIBITION_WINDOW_DURATION = [7200, 3600, 1200, 360];
  const CLIP_WINDOW_DURATION_IN_SECONDS = 10800;
  const FIXED_CRITICAL_INTERVAL = 60 //10 min
  const DELAY_TO_LIVE = 1800; // 30 min

  const lastSelectedSourceByUser = useRef('');

  const clipWindow = useRef({
    currentStart: 0,
    currentEnd: 0,
    durationInSeconds: CLIP_WINDOW_DURATION_IN_SECONDS,
    currentSource: 'POAATL',
    clipUUID: '',
    criticalStart: 0,
    criticalEnd: 0,
  });
  const clipWindowMockDataToUpdateClipWindowAfterConfirmationModal = useRef({
    currentStart: 0,
    currentEnd: 0,
    criticalStart: 0,
    criticalEnd: 0
  })
 
  const exhibitionWindowMockDataToReturnAfterCancelModalRef = useRef({
    currentStart: 0,
    currentEnd: 0,
    currentTime: 0,
  });

  const exhibitionWindowMockDataToUpdateExhibitionWindowAfterConfirmationModal = useRef({
    currentStart: 0,
    currentTime: 0,
    currentEnd: 0,
    isFuture: false
  });
  const [exhibitionWindow, setExhibitionWindow] = useState({
    currentStart: 0,
    currentTime: 0,
    currentEnd: 0
  });
  const exhibitionWindowRef = useRef({
    currentStart: 0,
    currentTime: 0,
    currentEnd: 0
  })
  const [timelineOptions] = useState<TimelineOptions>({
    width: '800px',
    height: '100px',
    stack: false,
    showMajorLabels: true,
    showCurrentTime: false,
    autoResize: false,
    zoomable: false,
    // horizontalScroll: false,
    // moveable: false,
    zoomFriction: 5,
    zoomMin: 360000,
    zoomMax: 7200000,
    type: 'range',
    locales: {
      'pt_BR': {
        current: 'pt_BR',
        time: 'hora',
      }
    },
    locale: 'pt_BR'
  })
  const hasCustomId = useRef(false);

  //### Player
  type PlayerStatus = 'loading' | 'loaded' | 'playing' | 'paused'
  const playerRef = useRef<any>(null);

  //const playerStatus = useRef<PlayerStatus>("loading");
  const [currentPlayerStatus, setCurrentPlayerStatus] = useState<PlayerStatus>("loading"); //Controla o index do zoom atual
  const playerStatus = useRef(currentPlayerStatus);

  const wasDragged = useRef(false);
  const lastStatusBeforeDrag = useRef<PlayerStatus>("playing");
  
  //###  Clip
  const [isClipDialogOpen, setIsClipDialogOpen] = useState(false);
  const [cuttingMark, setCuttingMark] = useState({
    start: 0,
    end: 0
  });
  const isSeekingToDownlod = useRef(false);
  const [formattedClipCutDuration, setFormattedClipCutDuration] = useState('00:00');
  const hasSelectedDate = useRef(false);
  const [searchTimestampSelectedByUser, setSearchTimestampSelectedByUser] = useState(0);
  
  const getCurrentEpoch = () => new Date().getTime();

  function isFutureTimestamp(timestamp: number) {
    return timestamp + (DELAY_TO_LIVE * 1000) > getCurrentEpoch();
  }

  function getDelayToLiveInSeconds(timestamp: number) {
    return  isFutureTimestamp(timestamp) ? DELAY_TO_LIVE : 0 //30 min em segundos
  }

  function estimateDateTimeFrom (timestamp: number) {
    let delayToLive = getDelayToLiveInSeconds(timestamp);
    let dateTimeFrom = timestamp - ((EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] + delayToLive) * 1000);
    let date = new Date(dateTimeFrom);

    date.setMilliseconds(0);
    
    return date;
  }

  function estimateDateTimeTo(timestamp: number) {
    let delayToLive = getDelayToLiveInSeconds(timestamp);
    let dateTimeTo = timestamp - (delayToLive * 1000);
    let date = new Date(dateTimeTo);

    date.setMilliseconds(0);

    return date;
  }

  const estimateCurrentDateTime = (timestamp: number) => {
    let delayToLive = getDelayToLiveInSeconds(timestamp);
    let currentTime = timestamp - ((EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] / 2) + delayToLive) * 1000;
    let date = new Date(currentTime);

    date.setMilliseconds(0);

    return date;
  }

  const estimateCurrentDateTimeWithoutDelayToLive = (timestamp: number) => {
    
    let currentTime = timestamp - ((EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] / 2)) * 1000;
    let date = new Date(currentTime);

    date.setMilliseconds(0);

    return date;
  }

  //ExhibitionWindow
  function estimateExhibitionWindowsCurrentStartByCurrentTime(timestamp: number) {
    return timestamp - (EXHIBITION_WINDOW_DURATION[currentZoomIndex] / 2) * 1000;
  }

  function estimateExhibitionWindowsCurrentEndByCurrentTime(timestamp: number) {
    return timestamp + (EXHIBITION_WINDOW_DURATION[currentZoomIndex] / 2) * 1000;
  }

  //ClipWindow Area
  function estimateClipWindowCurrentStartByCurrentTime(currentTimestamp: number) {
    let exhibitionStart = estimateExhibitionWindowsCurrentStartByCurrentTime(currentTimestamp);
    return exhibitionStart - FIXED_CRITICAL_INTERVAL * 1000
  }

  function estimateClipWindowsCurrentEndByCurrentTimeByWindowsCurrentStart(clipWindowCurrentStartTimestamp: number){
    return clipWindowCurrentStartTimestamp + clipWindow.current.durationInSeconds * 1000;
  }

  //Critical Area
  function estimateClipWindowsCriticalStartByCurrentTime(timestamp: number){
    return estimateExhibitionWindowsCurrentStartByCurrentTime(timestamp);
  }

  function estimateClipWindowsCriticalEndByCurrentTime(timestamp: number){
    return estimateClipWindowsCurrentEndByCurrentTimeByWindowsCurrentStart(timestamp) - FIXED_CRITICAL_INTERVAL * 1000
  }

  function handleZoomIn(){
    if (currentZoomIndex === 3) {
      return;
    }
    let currentIndexCount = Math.min(currentZoomIndexRef.current + 1, 3);
    setCurrentZoomIndex(currentIndexCount);
    setTimelineWindow(EXHIBITION_WINDOW_DURATION[currentIndexCount] / 2);
  }

  function handleZoomOut(){
    if (currentZoomIndex === 0) {
      return;
    }
    let currentIndexCount = Math.max(currentZoomIndexRef.current - 1, 0);
    setCurrentZoomIndex(currentIndexCount);
    setTimelineWindow(EXHIBITION_WINDOW_DURATION[currentIndexCount] / 2);
  }

  const getClip = (source: string, dateTimeFrom: number, duration: number) => {
    setIsLoadingNewMedia(true);
    let date = new Date(dateTimeFrom);
    date.setMilliseconds(0);
    return axios.post<AudioProps>(`/clips?source_name=${source}&datetime_from=${date.getTime()}&duration=${duration}`);

    //  return new Promise<PromiseAudioProps>((resolve => {
    //    const res = {} as PromiseAudioProps;
    //    const data = {} as AudioProps;
    //    data.address = "http://189.4.83.83:8001/POAATL/0109e9a1-7ea0-40ea-834c-bee737c15a8a/index.m3u8";
    //    data.uuid = "701d7727-c060-495b-8613-5d97f5b520ee";
    //    res.data = data;
    //    setTimeout(() => {
    //      resolve(res);
    //    }, 2000);
    //  }));
  }

  const loadClippingSourcesAndRenderFirstClip = () => {
    if(!currentClipToDisplay) {
      playerStatus.current = 'loaded';
      return;
    }

    let clipWindowStart = getCurrentEpoch() - (DELAY_TO_LIVE * 1000) - (clipWindow.current.durationInSeconds! * 1000);
    let clipWindowEnd = getCurrentEpoch() - DELAY_TO_LIVE * 1000;
    
    let criticalStart = clipWindowStart + (FIXED_CRITICAL_INTERVAL * 1000);
    let criticalEnd = clipWindowEnd - (FIXED_CRITICAL_INTERVAL * 1000);
    
    let exhibitionWindowEnd = getCurrentEpoch() - (DELAY_TO_LIVE * 1000) - (clipWindow.current.durationInSeconds! * 1000) + (EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] * 1000) + (FIXED_CRITICAL_INTERVAL * 1000);
    let exhibitionWindowStart = estimateDateTimeFrom(exhibitionWindowEnd).getTime();
    let exhibitionWindowCurrent = estimateCurrentDateTime(exhibitionWindowEnd).getTime();

    clipWindow.current.currentStart = clipWindowStart;
    clipWindow.current.currentEnd = clipWindowEnd;
    clipWindow.current.criticalStart = criticalStart;
    clipWindow.current.criticalEnd = criticalEnd;

    exhibitionWindowRef.current = {
      currentStart: exhibitionWindowStart,
      currentTime: exhibitionWindowCurrent,
      currentEnd: exhibitionWindowEnd
    }

    setExhibitionWindow({
      currentStart: exhibitionWindowStart,
      currentTime: exhibitionWindowCurrent,
      currentEnd: exhibitionWindowEnd
    });

    exhibitionWindowMockDataToReturnAfterCancelModalRef.current = {
      currentStart: exhibitionWindowStart,
      currentTime: exhibitionWindowCurrent,
      currentEnd: exhibitionWindowEnd
    }

    playerStatus.current = 'loaded';

    const clipNameAndLocationName = currentClipToDisplay.name.replace('-', '')
    
    getClip(clipNameAndLocationName, clipWindowStart, clipWindow.current.durationInSeconds!).then(res => {
      setAudioUrl(res.data.address);
      setIsLoadingNewMedia(false);
      const playerCurrentTime = Math.trunc((exhibitionWindowCurrent - clipWindowStart) / 1000);
      playerRef.current.currentTime = playerCurrentTime;

      //Caso não tenha o id criado ele adiciona, contrário a isto, ele edita
      if(!hasCustomId.current) {
        timelineRef?.current['$el'].addCustomTime(exhibitionWindowCurrent, 1);
        hasCustomId.current = true;
      }else {
        timelineRef?.current['$el'].setCustomTime(exhibitionWindowCurrent, 1);
      }

      setSelectedDate(moment(exhibitionWindowCurrent).locale('pt-br').format(dateTimePickerFormat));
      
      timelineRef?.current['$el'].setOptions({start: exhibitionWindowStart, end: exhibitionWindowEnd});
      updateRedCriticalArea(criticalStart, criticalEnd);
      handlePlayButton();
      clipWindow.current.clipUUID = res.data.uuid;
    }).catch(err => {
      setIsLoadingNewMedia(false);
      setCurrentClipToDisplay(undefined);
    })
  }
  
  const handleFilter = () => {
    if(debouncedFilterTerm === ''){
      setFilteredClips(clippings);
      return;
    }
    
    let filteredList = clippings.filter(clip => 
      clip.location_label.toLowerCase().includes(debouncedFilterTerm.toLowerCase()) || 
      clip.channel_label.toLowerCase().includes(debouncedFilterTerm.toLowerCase())
    );

    setFilteredClips(filteredList);
  }

  const setTimelineWindow = (timeInMinutes: number) => {
    let exhibitionWindowStart = moment(exhibitionWindowRef.current.currentTime).subtract(timeInMinutes, 'seconds');
    let exhibitionWindowEnd = moment(exhibitionWindowRef.current.currentTime).add(timeInMinutes, 'seconds');

    timelineRef?.current['$el'].setOptions({
      start: exhibitionWindowStart,
      end: exhibitionWindowEnd}
    )

    timelineRef.current['$el'].redraw();
  }

  const isWithinCriticalSpace = (timestamp: number) => {
    return timestamp < clipWindow.current?.criticalStart || timestamp > clipWindow.current?.criticalEnd;
  }

  const generateTimelineRangeItems = (criticalStart: number, criticalEnd: number, clipArea?: Date[]) => {
    const timelineItemsList = [
      {
        id: 1, 
        type: 'range',
        content:'', 
        start: new Date('2023-11-20 00:00:00'), 
        end: new Date(criticalStart), 
        editable: {
          updateTime: false
        }, 
        className: 'proibido',
        selectable: false,
      },
      {
        id: 2, 
        type: 'range',
        content:'', 
        start: new Date(criticalEnd), 
        end: new Date().getTime() + 86_400_000, 
        editable: {
          updateTime: false
        }, 
        className: 'proibido',
        selectable: false,
      }
    ];

    if(clipArea) {
      timelineItemsList.push({
        id: 3, 
        type: 'range',
        content: "", 
        start: clipArea[0], 
        end: clipArea[1], 
        editable: {
          updateTime: false
        }, 
        className: 'clip-download-area',
        selectable: false,
      },)
    }

    return timelineItemsList;
  } 

  const updateRedCriticalArea = (currentCriticalStart: number, currentCriticalEnd: number) => {
    const timelineItemsList = generateTimelineRangeItems(currentCriticalStart, currentCriticalEnd);
    
    timelineRef?.current['$el'].setItems(timelineItemsList);
  }

  const lastSelectedSeekDate = useRef(0);

  const rangeChangeHandler = (e: any) => {
    if(!e.byUser){ 
      return;
    }; 
    
    let exhibitionCurrentTime = estimateCurrentDateTimeWithoutDelayToLive(e.end.getTime());
    timelineRef?.current['$el'].setCustomTime(moment(exhibitionCurrentTime), 1);

    exhibitionWindowCurrentTime.current = exhibitionCurrentTime

    if(exhibitionWindowCurrentTimeTimeout.current === undefined) {
      exhibitionWindowCurrentTimeTimeout.current = setTimeout(() => {

        exhibitionWindowRef.current = {
          currentStart: exhibitionWindowRef.current.currentStart,
          currentTime: exhibitionWindowCurrentTime.current.getTime(),
          currentEnd: exhibitionWindowRef.current.currentEnd
        }

        setExhibitionWindow({
          ...exhibitionWindowRef.current,
          currentTime: exhibitionWindowCurrentTime.current.getTime()
        });

        exhibitionWindowCurrentTimeTimeout.current = undefined;
        clearTimeout(exhibitionWindowCurrentTimeTimeout.current);
      }, 600)
    }

    if(isSeekingToDownlod.current) {
      let start = new Date(lastSelectedSeekDate.current);
      let end = exhibitionCurrentTime;

      if(moment(exhibitionCurrentTime).isBefore(lastSelectedSeekDate.current)){
        start = exhibitionCurrentTime;
        end = new Date(lastSelectedSeekDate.current);
      }

      const criticalAreaStart = clipWindow.current.criticalStart;
      const criticalAreaEnd = clipWindow.current.criticalEnd;

      const clipArea = [start, end];

      if(!isWithinCriticalSpace(exhibitionCurrentTime.getTime())) {
        const formattedClipDownloadDuration = calculateDiffBetweenClipDates(start.getTime(), end.getTime());
        setFormattedClipCutDuration(formattedClipDownloadDuration);

        //Fazer a duration, que é a diferença de tempos in out para formatar certo
        const items = generateTimelineRangeItems(criticalAreaStart, criticalAreaEnd, clipArea);
        timelineRef?.current['$el'].setItems(items);
      }
    }
    
    if(!wasDragged.current) {
      lastStatusBeforeDrag.current = playerStatus.current;
      handleForcePause();
    }

    wasDragged.current = true;
  }

  function calculateDiffBetweenClipDates(start: number, end: number) {
    const diffInMs = end - start;
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

  const rangeChangedHandler = (e: any) => {
    if(!e.byUser){ 
      return;
    };

    let exhibitionWindowCurrentTime = e.end.getTime() - (EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] /2 * 1000);

    let isFuture = isFutureTimestamp(exhibitionWindowCurrentTime);

    if(isFuture) {
      dialogContext.setDialogStatus(true);
      return;
    }
    exhibitionWindowCurrentTimeTimeout.current = undefined;
    clearTimeout(exhibitionWindowCurrentTimeTimeout.current);

    //let isFuture = isFutureTimestamp(e.end.getTime());
    let currentTimestampReference = isFuture ? getCurrentEpoch() : e.end.getTime();
    let exhibitionCurrentStart = e.start;
    let exhibitionCurrentEnd = e.end;
    let exhibitionCurrentTime = new Date(exhibitionWindowCurrentTime);

    let clipCurrentStart = clipWindow.current.currentStart;

    handleGetLastPlayerStatus();
    wasDragged.current = false;

    if(isWithinCriticalSpace(exhibitionCurrentTime.getTime())){ 
      clipCurrentStart = exhibitionCurrentStart.getTime();
      let clipCurrentEnd = clipCurrentStart + (clipWindow.current.durationInSeconds * 1000);
      let currentCriticalStart = clipCurrentStart + (FIXED_CRITICAL_INTERVAL * 1000);
      let currentCriticalEnd = clipCurrentEnd - (FIXED_CRITICAL_INTERVAL * 1000);

      const clipWindowMockData = {
        currentStart: clipCurrentStart,
        currentEnd: clipCurrentEnd,
        criticalStart: currentCriticalStart,
        criticalEnd : currentCriticalEnd
      }
      
      const exhibitionWindowMockData = {
        ...exhibitionWindowRef.current,
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime(),
        isFuture: isFuture
      }

      //atualiza as referencias de mock para utilizar na confirmação do modal passada para o usuário
      clipWindowMockDataToUpdateClipWindowAfterConfirmationModal.current = clipWindowMockData;
      exhibitionWindowMockDataToUpdateExhibitionWindowAfterConfirmationModal.current = exhibitionWindowMockData;

      handleForcePause();

      setIsCriticalModalOpen(true);
    }else {
      const exhibitionWindowMockDataToBeConsumedByCancelModal = {
        ...exhibitionWindowRef.current,
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime()
      }
      
      exhibitionWindowMockDataToReturnAfterCancelModalRef.current = exhibitionWindowMockDataToBeConsumedByCancelModal;

      exhibitionWindowRef.current = {
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime()
      }

      setExhibitionWindow({
        ...exhibitionWindowRef.current,
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime()
      });

      if(isFuture) {
        handleForcePause();
        timelineRef?.current['$el'].setOptions({start: exhibitionCurrentStart, end: exhibitionCurrentEnd});
        timelineRef?.current['$el'].setCustomTime(exhibitionCurrentTime, 1);

        return;
      }

      const playerCurrentTime = Math.trunc((exhibitionCurrentTime.getTime() - clipCurrentStart) / 1000);
      playerRef.current.currentTime = playerCurrentTime;

      timelineRef?.current['$el'].setOptions({start: exhibitionCurrentStart, end: exhibitionCurrentEnd});
      timelineRef?.current['$el'].setCustomTime(exhibitionCurrentTime, 1);
    }
  }

  async function handleConfirmationModal() {
    //confirmation modal logic
    setIsCriticalModalOpen(false);
    setIsLoadingNewMedia(true);

    const { currentStart, currentEnd, criticalStart, criticalEnd } = 
    clipWindowMockDataToUpdateClipWindowAfterConfirmationModal.current;
    
    const clipNameAndLocationName = currentClipToDisplay?.name.replace('-', '');

    const res = await getClip(clipNameAndLocationName!, currentStart, clipWindow.current.durationInSeconds);
    setAudioUrl(res.data.address);
    setIsLoadingNewMedia(false);

    //#### atualiza as referencias do window utilizando a ref de mock da window
    clipWindow.current.currentStart = currentStart;
    clipWindow.current.currentEnd = currentEnd;
    clipWindow.current.criticalStart = criticalStart;
    clipWindow.current.criticalEnd = criticalEnd;

    updateRedCriticalArea(
      clipWindowMockDataToUpdateClipWindowAfterConfirmationModal.current.criticalStart, 
      clipWindowMockDataToUpdateClipWindowAfterConfirmationModal.current.criticalEnd
    );

    handlePlayButton();

    const { currentStart: exhibitionCurrentStart, currentTime, currentEnd: exhibitionCurrentEnd, isFuture } = 
    exhibitionWindowMockDataToUpdateExhibitionWindowAfterConfirmationModal.current;

    exhibitionWindowRef.current = {
      currentStart: exhibitionCurrentStart,
      currentTime: currentTime,
      currentEnd: exhibitionCurrentEnd
    }

    setExhibitionWindow({
      ...exhibitionWindowRef.current,
      currentStart: exhibitionCurrentStart,
      currentTime: currentTime,
      currentEnd: exhibitionCurrentEnd
    });

    if(isFuture) {
      handleForcePause();
      timelineRef?.current['$el'].setOptions({start: exhibitionCurrentStart, end: exhibitionCurrentEnd});
      timelineRef?.current['$el'].setCustomTime(currentTime, 1);

      return;
    }

    const playerCurrentTime = Math.trunc((currentTime - exhibitionCurrentStart) / 1000);
    playerRef.current.currentTime = playerCurrentTime;

    timelineRef?.current['$el'].setOptions({start: exhibitionCurrentStart, end: exhibitionCurrentEnd});
    timelineRef?.current['$el'].setCustomTime(currentTime, 1);
  }

  function handleCancelModal() {
    const { currentStart, currentTime, currentEnd } = exhibitionWindowMockDataToReturnAfterCancelModalRef.current;
    let exhibitionCurrentTime = estimateCurrentDateTimeWithoutDelayToLive(currentEnd);

    exhibitionWindowRef.current = {
      currentStart: currentStart,
      currentTime: currentTime,
      currentEnd: currentEnd
    }

    setExhibitionWindow({
      currentStart,
      currentTime,
      currentEnd
    })

    setIsCriticalModalOpen(false);
    timelineRef?.current['$el'].setOptions({
      ...timelineOptions,
      start: currentStart, 
      end: currentEnd
    });
    timelineRef?.current['$el'].setCustomTime(currentTime, 1);
    exhibitionWindowCurrentTime.current = exhibitionCurrentTime;
    handleClearCuttingMark();
  }

  function handleTimeUpdate(e: any) {
    if(playerStatus.current === 'paused') {       
      return;
    }

    if(playerStatus.current === 'loaded') {       
      return;
    }

    const currentTimeOffset = Math.trunc(e.target.currentTime * 1000);
    const exhibitionCurrentTime = clipWindow.current.currentStart + currentTimeOffset;
    const exhibitionCurrentStart = exhibitionCurrentTime - ((EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] / 2) * 1000)
    const exhibitionCurrentEnd = exhibitionCurrentTime + ((EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] / 2) * 1000);

    let clipCurrentStart = exhibitionCurrentStart;
    let clipCurrentEnd = clipCurrentStart + (clipWindow.current.durationInSeconds! * 1000);

    let currentCriticalStart = clipCurrentStart + (FIXED_CRITICAL_INTERVAL * 1000);
    let currentCriticalEnd = clipCurrentEnd - (FIXED_CRITICAL_INTERVAL * 1000);

    if(isWithinCriticalSpace(exhibitionWindowRef.current.currentTime)){
      setIsCriticalModalOpen(true);
      handleForcePause();

      const clipWindowMockData = {
        currentStart: clipCurrentStart,
        currentEnd: clipCurrentEnd,
        criticalStart: currentCriticalStart,
        criticalEnd : currentCriticalEnd
      }

      const exhibitionWindowMockData = {
        ...exhibitionWindowRef.current,
        currentStart: exhibitionCurrentStart,
        currentTime: exhibitionCurrentTime,
        currentEnd: exhibitionCurrentEnd,
        isFuture: false,
      }

      //atualiza as referencias de mock para utilizar na confirmação do modal passada para o usuário
      clipWindowMockDataToUpdateClipWindowAfterConfirmationModal.current = clipWindowMockData;
      exhibitionWindowMockDataToUpdateExhibitionWindowAfterConfirmationModal.current = exhibitionWindowMockData;
    }else {
      exhibitionWindowRef.current = {
        currentStart: exhibitionCurrentStart,
        currentTime: exhibitionCurrentTime,
        currentEnd: exhibitionCurrentEnd
      }

      setExhibitionWindow(prev => ({
        currentTime: exhibitionCurrentTime,
        currentStart: exhibitionCurrentStart,
        currentEnd: exhibitionCurrentEnd
      }));

      if(isSeekingToDownlod.current) {
        let start = new Date(lastSelectedSeekDate.current);
        let end = new Date (exhibitionCurrentTime);
  
        if(moment(exhibitionCurrentTime).isBefore(lastSelectedSeekDate.current)){
          start = new Date(exhibitionCurrentTime);
          end = new Date(lastSelectedSeekDate.current);
        }
  
        const criticalAreaStart = clipWindow.current.criticalStart;
        const criticalAreaEnd = clipWindow.current.criticalEnd;
  
        const clipArea = [start, end];
  

        const formattedClipDownloadDuration = calculateDiffBetweenClipDates(start.getTime(), end.getTime());
        setFormattedClipCutDuration(formattedClipDownloadDuration);

        //Fazer a duration, que é a diferença de tempos in out para formatar certo
        const items = generateTimelineRangeItems(criticalAreaStart, criticalAreaEnd, clipArea);
        timelineRef?.current['$el'].setItems(items);
        
      }
  
      timelineRef?.current['$el'].setOptions({start: exhibitionCurrentStart, end: exhibitionCurrentEnd});
      timelineRef?.current['$el'].setCustomTime(exhibitionCurrentTime, 1);
      timelineRef?.current['$el'].redraw();
    }
  }

  function handleGetLastPlayerStatus() {
    if(lastStatusBeforeDrag.current === 'playing') {
      handlePlay();
    }else {
      handleForcePause();
    }
  }

  const handlePlay = async () => {
    try {
      await playerRef.current.play();
      playerStatus.current = 'playing';
      setCurrentPlayerStatus('playing');
    } catch (err) {
      console.log(err);
    }
  }

  const handleForcePause = () => {
    playerRef.current.pause();
    playerStatus.current = 'paused';
    setCurrentPlayerStatus('paused');
  }

  const handlePlayButton = useCallback(() => {
    if (playerRef.current.paused) {
      handlePlay();
    } else {
      handleForcePause();
    }
  }, [])

  const navigateInTimeline = (duration: number) => {
    let isFuture = isFutureTimestamp(exhibitionWindowRef.current.currentTime + duration);

    if(isFuture) {
      dialogContext.setDialogStatus(true);
      return;
    }
    
    const exhibitionWindowMockDataToBeConsumedByCancelModal = {
      ...exhibitionWindowRef.current,
      currentStart: exhibitionWindowRef.current.currentStart,
      currentTime: exhibitionWindowRef.current.currentTime,
      currentEnd: exhibitionWindowRef.current.currentEnd
    }
    
    exhibitionWindowMockDataToReturnAfterCancelModalRef.current = exhibitionWindowMockDataToBeConsumedByCancelModal;

    let exhibitionCurrentStart = new Date(exhibitionWindowRef.current.currentStart + duration);
    let exhibitionCurrentEnd = new Date(exhibitionWindowRef.current.currentEnd + duration);
    let exhibitionCurrentTime = new Date(exhibitionWindowRef.current.currentTime + duration);
    
    if(isWithinCriticalSpace(exhibitionCurrentTime.getTime())) {
      handleForcePause();
      setIsCriticalModalOpen(true);

      let clipCurrentStart = exhibitionCurrentStart.getTime();
      let clipCurrentEnd = clipCurrentStart + (clipWindow.current.durationInSeconds! * 1000);

      let currentCriticalStart = clipCurrentStart + (FIXED_CRITICAL_INTERVAL * 1000);
      let currentCriticalEnd = clipCurrentEnd - (FIXED_CRITICAL_INTERVAL * 1000);


      const clipWindowMockData = {
        currentStart: clipCurrentStart,
        currentEnd: clipCurrentEnd,
        criticalStart: currentCriticalStart,
        criticalEnd : currentCriticalEnd
      }
      
      const exhibitionWindowMockData = {
        ...exhibitionWindowRef.current,
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime(),
        isFuture: isFuture
      };

      clipWindowMockDataToUpdateClipWindowAfterConfirmationModal.current = clipWindowMockData;
      exhibitionWindowMockDataToUpdateExhibitionWindowAfterConfirmationModal.current = exhibitionWindowMockData;
    } else {
      if(isSeekingToDownlod.current) {
        let start = new Date(lastSelectedSeekDate.current);
        let end = exhibitionCurrentTime;
  
        if(moment(exhibitionCurrentTime).isBefore(lastSelectedSeekDate.current)){
          start = exhibitionCurrentTime;
          end = new Date(lastSelectedSeekDate.current);
        }
  
        const criticalAreaStart = clipWindow.current.criticalStart;
        const criticalAreaEnd = clipWindow.current.criticalEnd;
  
        const clipArea = [start, end];

        const formattedClipDownloadDuration = calculateDiffBetweenClipDates(start.getTime(), end.getTime());
        setFormattedClipCutDuration(formattedClipDownloadDuration);

        const items = generateTimelineRangeItems(criticalAreaStart, criticalAreaEnd, clipArea);
        timelineRef?.current['$el'].setItems(items);
        timelineRef.current['$el'].redraw();
      }

      exhibitionWindowRef.current = {
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime()
      }

      setExhibitionWindow({
        ...exhibitionWindowRef.current,
        currentStart: exhibitionCurrentStart.getTime(),
        currentTime: exhibitionCurrentTime.getTime(),
        currentEnd: exhibitionCurrentEnd.getTime()
      })
  
      let clipCurrentStart = clipWindow.current.currentStart;
      const playerCurrentTime = Math.trunc((exhibitionCurrentTime.getTime() - clipCurrentStart) / 1000);
      playerRef.current.currentTime = playerCurrentTime;

      setTimelineWindow(EXHIBITION_WINDOW_DURATION[currentZoomIndexRef.current] / 2);
      // timelineRef?.current['$el'].setOptions({ start: exhibitionCurrentStart, end: exhibitionCurrentEnd });
      timelineRef?.current['$el'].setCustomTime(exhibitionCurrentTime, 1);
      timelineRef?.current['$el'].redraw();
    }
  }

  function handleDateChange(evt: any) {
    setSelectedDate(evt.target.value);
    hasSelectedDate.current = true;
  };

  async function handleSearchAndPlayClipByTimestamp() {
    const currentSelectedTimestampByUser = new Date(selectedDate).getTime();
    handleClearCuttingMark()//anula o IN em caso de pesquisa de data

    if (isNaN(currentSelectedTimestampByUser)) {
      return;
    }

    let isFuture = isFutureTimestamp(currentSelectedTimestampByUser);

    if(isFuture) {
      dialogContext.setDialogStatus(true);
      return;
    }

    handleForcePause();

    setSearchConfirmationModal(true);

    setSearchTimestampSelectedByUser(currentSelectedTimestampByUser);
  }

  async function handleConfirmSearchModal() { //OK
    setIsLoadingNewMedia(true);
    setSearchConfirmationModal(false);

    let exhibitionCurrentStart = estimateExhibitionWindowsCurrentStartByCurrentTime(searchTimestampSelectedByUser);
    let exhibitionCurrentEnd = estimateExhibitionWindowsCurrentEndByCurrentTime(searchTimestampSelectedByUser);

    let clipWindowStart = estimateClipWindowCurrentStartByCurrentTime(searchTimestampSelectedByUser);
    let clipWindowEnd = estimateClipWindowsCurrentEndByCurrentTimeByWindowsCurrentStart(clipWindowStart)

    let criticalStart = estimateClipWindowsCriticalStartByCurrentTime(searchTimestampSelectedByUser);
    let criticalEnd = estimateClipWindowsCriticalEndByCurrentTime(clipWindowStart);

    timelineRef?.current['$el'].setOptions({start: exhibitionCurrentStart, end: exhibitionCurrentEnd});
    timelineRef?.current['$el'].setCustomTime(searchTimestampSelectedByUser, 1);

    updateRedCriticalArea(criticalStart, criticalEnd);

    exhibitionWindowRef.current = {
      currentStart: exhibitionCurrentStart,
      currentTime: searchTimestampSelectedByUser,
      currentEnd: exhibitionCurrentEnd
    }

    setExhibitionWindow({
      currentStart: exhibitionCurrentStart,
      currentTime: searchTimestampSelectedByUser,
      currentEnd: exhibitionCurrentEnd,
    })

    //currentStart
    const clipNameAndLocationName = currentClipToDisplay!.name.replace('-', '')
    await getClip(clipNameAndLocationName, clipWindowStart, clipWindow.current.durationInSeconds!).then(res => {
      clipWindow.current = {
        ...clipWindow.current,
        currentStart: clipWindowStart,
        currentEnd: clipWindowEnd,
        criticalStart: criticalStart,
        criticalEnd: criticalEnd,
        clipUUID: res.data.uuid
      }
      setAudioUrl(res.data.address);
      setIsLoadingNewMedia(false);
      playerRef.current.currentTime = FIXED_CRITICAL_INTERVAL + EXHIBITION_WINDOW_DURATION[currentZoomIndex] / 2;
    }).catch(err => {
      console.log(err);
      
    })

  }

  function handleCancelSearchModal() { //CANCELAR
    setSearchConfirmationModal(false);
  }

  function handleCancelChangeSourceModal() { //CANCELAR
    setChangeSourceModal(false);
    setIsLoadingNewMedia(false);
  }

  function handleConfirmChangeSourceModal() { //OK
    setChangeSourceModal(false);
    setIsLoadingNewMedia(true);

    clipWindow.current = {
      ...clipWindow.current,
      currentSource: currentClipToDisplay?.name ?? "POAATL"
    }
    
    loadClippingSourcesAndRenderFirstClip();
  }

  function formatTime(segundos: number) {
    let [integer,] = String(segundos).split('.'); 
    
    let hours = parseInt(integer) / 3600;
    let minutos = (parseInt(integer) / 60) % 60;
    let segundosRestantes = parseInt(integer) % 60;
    
    let formattedHour = String(Math.trunc(hours)).padStart(2, '0');
    let minutosFormatados = String(Math.trunc(minutos)).padStart(2, '0');
    let segundosFormatados = String(Math.trunc(segundosRestantes)).padStart(2, '0');
    
    return formattedHour + ':' + minutosFormatados + ':' + segundosFormatados;
  }

  function calculateStartAndStopDifferenceAndReturnBoth() {
    const seek = (cuttingMark.start - clipWindow.current.currentStart) / 1000;
    const to = (cuttingMark.end - clipWindow.current.currentStart) / 1000;

    return { seek: formatTime(seek), to: formatTime(to) }
  }

  const CuttingIcon = ({ icon = 'busy' }: CuttingIconProps) => {
    const Icon = {
      available: <EventAvailable color='primary' />,
      busy: <EventBusy color='action' />
    }

    return (
      <>{Icon[icon]}</>
    ) 
  }

  const handleRequestUserPermissionToStartCuttingDownload = () => {
    handleForcePause();
    setIsClipDialogOpen(true);
  }

  const handleClearCuttingMark = (status?: { keepFirstEntry: boolean }) => {
    setIsClipDialogOpen(false);

    if(status?.keepFirstEntry) {
      setCuttingMark(prev => ({
        ...prev,
        end: 0
      }));

      return;
    }

    setCuttingMark(() => ({
      start: 0,
      end: 0
    }));

    isSeekingToDownlod.current = false;
    handleResetFormattedClipDownloadArea()

    updateRedCriticalArea(clipWindow.current.criticalStart, clipWindow.current.criticalEnd)
  }

  function handleResetFormattedClipDownloadArea() {
    setFormattedClipCutDuration('00:00');
  }

  const handleConfirmClipgDownload = () => {
    setIsLoadingNewMedia(true);
    handleTrimVideo();
    setIsClipDialogOpen(false);
    handleClearCuttingMark();
  }

  const handleSeekSelection = () => { 
    //Reseta o estado do icon zerando o valor do start
    if(cuttingMark.start > 0) {
      handleClearCuttingMark();
      isSeekingToDownlod.current = false;
      handleResetFormattedClipDownloadArea()

      return;
    }

    //Inverte os valores de start e end caso o start seja maior que o end
    if(exhibitionWindowRef.current.currentTime > cuttingMark.end && cuttingMark.end > 0) {
      setCuttingMark(prev => ({
        ...prev,
        start: cuttingMark.end,
        end: exhibitionWindowRef.current.currentTime
      }));

      handleRequestUserPermissionToStartCuttingDownload();

      return;
    }

    setCuttingMark(prev => ({
      ...prev,
      start: exhibitionWindowRef.current.currentTime
    }));

    if(cuttingMark.end) {
      handleRequestUserPermissionToStartCuttingDownload();
    }

    isSeekingToDownlod.current = true;
    lastSelectedSeekDate.current = exhibitionWindowRef.current.currentTime;
  }

  const handleToSelection = () => {
    
    //Reseta o valor de zero caso já existe um valor selecionado anteriormente
    if(cuttingMark.end > 0) {
      setCuttingMark(prev => ({
        ...prev,
        end: 0
      }));

      handleResetFormattedClipDownloadArea();

      return;
    }

    //Inverte os valores de end e start caso end seja menor que start
    if(exhibitionWindowRef.current.currentTime < cuttingMark.start) {
      setCuttingMark(prev => ({
        ...prev,
        end: prev.start,
        start: exhibitionWindowRef.current.currentTime,
      }));


      setTimeout(() => {
        handleRequestUserPermissionToStartCuttingDownload();
      }, 1500)

      return;
    }

    setCuttingMark(prev => ({
      ...prev,
      end: exhibitionWindowRef.current.currentTime
    }));

    if(cuttingMark.start > 0) {
      handleRequestUserPermissionToStartCuttingDownload();
    }
  }

  const handleTrimVideo = async() => {
    const { seek, to } = calculateStartAndStopDifferenceAndReturnBoth();

    let address = trimUrlPattern
    .replace("{seek}", seek)
    .replace("{to}", to)
    .replace("{clipId}", clipWindow.current.clipUUID);

    const storage = getLocalStorageData();

    let config = {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${storage.token}`
      },
      credentials: 'include'
    } as any;

    try {
      const response = await fetch(address, config)

      if (response.status !== 200) {
        return;
      }

      if(!response.body) return;
      
      const reader = response.body.getReader();

      let chunks = []; 
      while(true) {
        const {done, value} = await reader.read();
        if (done) {
          setIsLoadingNewMedia(false);
          break;
        }

        chunks.push(value);
      }
      let blob = new Blob(chunks);
      
      var file = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = file;
      a.download = "trimmed-" + Math.random() * 10023 + ".mp3";
      document.body.appendChild(a);
      a.click();    
      a.remove();
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    loadClippingSourcesAndRenderFirstClip();
    let userData = getLocalStorageData();

    axiosCallHandler({ method: 'GET', url: `/users/${userData?.username}` })
    .then((currentUserServerData: UserData) => {
      axiosCallHandler({ method: 'GET', url: '/clipping-sources' }).then(res => {
        let response: ClippingSourceShape[] = [];
  
        for(let i=0; i < res.length; i++) {
          let item = res[i] as ClippingSourceShape;
          if (currentUserServerData.groups?.includes(item.name) && item.enabled) {
            response.push(item)
          } 
        }
  
        setClippings(response);
        setFilteredClips(response);
      });
    })

    window?.addEventListener('keydown', (e: any) => {
      if(e.target?.id === "filter-input"){
        return;
      }

      if(e.key === 'Space' || e.keyCode === 32) {
        handlePlayButton();
      }
    })
  }, [handlePlayButton]);

  useEffect(handleFilter, [debouncedFilterTerm]);
  useEffect(() => {
    if(!currentClipToDisplay){
      return;
    }

    if(clipWindow.current.clipUUID === '') {
      loadClippingSourcesAndRenderFirstClip();
      return;
    }

    setChangeSourceModal(true);
    
  }, [currentClipToDisplay])

  return (
    <div>
    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, ptBR)}>
      <PlayerLayout>
        <div style={{ height: '100dvh', width: '100%' }}>
          <SearchWrapper>
            <Filter value={filter} onChange={setFilter} id="filter-input" placeholder="Escolher a praça" />
          </SearchWrapper>
          <CardsContainer>
            {
              filteredClips?.map((clip) => {
                return (
                  <Card
                    {...clip} 
                    isFocused={currentClipToDisplay?.name === clip.name}
                    key={clip.name} 
                    label={clip.location_label} 
                    text={clip.channel_label}
                    setCurrentClip={(clipName) => {
                      setCurrentClipToDisplay(clipName); 
                      lastSelectedSourceByUser.current = clipName;
                    }}
                  />
                )
              })
            }
          </CardsContainer>
        </div>
        <PlayerArea>
          {/* <pre>{JSON.stringify({ 'exhibition-current-time': new Date(exhibitionWindowRef.current.currentTime) })}</pre> */}
          
          <Typography variant="h4">{currentClipToDisplay?.playerLabel}</Typography>
          <div style={{ display: 'none' }}>
            <ReactHlsPlayer
              src={audioUrl}
              // src={STATIC_URL}
              autoPlay={false}
              controls={true}
              playerRef={playerRef}
              width="100%"
              height="auto"
              onPause={() => playerStatus.current = 'paused'}
              onPlay={() => playerStatus.current = 'playing'}
              onTimeUpdate={handleTimeUpdate}
            />
          </div>
          {
            playerStatus.current === "loading" ?
              (<CircularProgress disableShrink />)
              :
              (
                currentClipToDisplay && (
                  <React.Fragment>
                   

                  <div className='datepicker-wrapper'>
                  
                    <section style={{ display: 'flex', gap: '1rem' }}>
                      <TextField
                        
                        id="datetime"
                        className='input-icon'
                        type="datetime-local"
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Button 
                        variant='outlined' 
                        onClick={handleSearchAndPlayClipByTimestamp}
                        disabled={!selectedDate}
                        startIcon={<SearchIcon />}
                      >
                        <Typography variant="button">Pesquisar</Typography>
                      </Button>
                    </section>
                    <IconsWrapper activeBorder={cuttingMark.start > 0}>
                      <Typography className='recorte-label'>Recorte</Typography>
                      <Button
                        variant="outlined"
                        startIcon={<CuttingIcon icon={cuttingMark.start ? 'available' : 'busy'} />}
                        onClick={handleSeekSelection}
                        size='medium'
                      >
                        <label className="recorte-icon">
                          In
                        </label>
                      </Button>

                      <div>
                        <Typography style={{ fontFamily: 'monospace' }}>
                          {formattedClipCutDuration}
                        </Typography>
                      </div>

                      <Button
                        variant="outlined"
                        disabled={cuttingMark.start <= 0}
                        className="recorte-icon"
                        startIcon={<CuttingIcon icon={cuttingMark.end ? 'available' : 'busy'} />}
                        onClick={handleToSelection}
                        size='medium'
                      >
                        <label className="recorte-icon">
                          Out
                        </label>
                      </Button>
                    </IconsWrapper>
                  </div>
                  <TimelineWrapper isLoadingNewContent={isLoadingNewMidia}>
                    <div className='loader-wrapper'>
                      <div className="loader"></div>
                    </div>
                      {
                        currentClipToDisplay && (
                          <Timeline
                            ref={timelineRef}
                            options={timelineOptions}
                            rangechangedHandler={rangeChangedHandler}
                            rangechangeHandler={rangeChangeHandler}
                          />
                        )
                      }
                    
                  </TimelineWrapper>
                  <TimeControllerContainer>
                    <TimeReference>{moment(exhibitionWindowRef.current.currentTime).format('HH:mm:ss')}</TimeReference>
                    <section className="zoom-controller">
                      <Button onClick={handleZoomOut} disabled={currentZoomIndex === 0} >
                        <img src={zoomOutIcon} alt="zoomout" />
                      </Button>
                      <Button onClick={handleZoomIn} disabled={currentZoomIndex === 3} >
                        <img src={zoomInIcon} alt="zoomin" />
                      </Button>
                    
                    </section>
                  </TimeControllerContainer>
                  <PlayerControllerContainer isLoadingNewContent={isLoadingNewMidia}>
                    <section className="controller">
                      <Button onClick={() => navigateInTimeline(NavigateToFuture['1_DAY'])} disabled={isLoadingNewMidia}><Typography variant="overline">+1d</Typography></Button>
                      <Button onClick={() => navigateInTimeline(NavigateToFuture['30_MINUTES'])} disabled={isLoadingNewMidia}><Typography variant="overline">+30m</Typography></Button>
                      <Button onClick={() => navigateInTimeline(NavigateToFuture['3_MINUTES'])} disabled={isLoadingNewMidia}><Typography variant="overline">+3m</Typography></Button>
                      <Button onClick={() => navigateInTimeline(NavigateToFuture['30_SECONDS'])} disabled={isLoadingNewMidia}><Typography variant="overline">+30s</Typography></Button>
                      {
                        playerStatus.current === 'playing' ? (
                          <Icon src={pauseIcon} alt="pause" width={40} onClick={handlePlayButton} key={1}/>
                        ) : (
                          <Icon src={playIcon} alt="play" width={40} onClick={handlePlayButton} key={2} />
                        )
                      }
                      <Button onClick={() => navigateInTimeline(NavigateToPast['30_SECONDS'])} disabled={isLoadingNewMidia}><Typography variant="overline">-30s</Typography></Button>
                      <Button onClick={() => navigateInTimeline(NavigateToPast['3_MINUTES'])} disabled={isLoadingNewMidia}><Typography variant="overline">-3m</Typography></Button>
                      <Button onClick={() => navigateInTimeline(NavigateToPast['30_MINUTES'])} disabled={isLoadingNewMidia}><Typography variant="overline">-30m</Typography></Button>
                      <Button onClick={() => navigateInTimeline(NavigateToPast['1_DAY'])} disabled={isLoadingNewMidia}><Typography variant="overline">-1d</Typography></Button>
                    </section>
                  </PlayerControllerContainer>
                  <SimpleDialog
                    openDialog={dialogContext.dialogStatus}
                    setOpenDialog={dialogContext.setDialogStatus}
                    dialogText="Não é possível pesquisar clipes futuros"
                  />
                  {/* #### CRITICAL AREA DIALOG #### */}
                  <AudioDialog
                    isOpen={isCriticalModalOpen}
                    onClose={() => {
                      setIsCriticalModalOpen(false)}
                    }
                    title="Requisição de novo conteúdo"
                    text="Será necessário fazer o download de um novo conteúdo para análise"
                  >
                    <Button onClick={handleCancelModal} color="primary" variant="outlined">
                      Cancelar
                    </Button>
                    <Button onClick={handleConfirmationModal} color="primary" autoFocus variant="contained">
                      OK
                    </Button>
                  </AudioDialog>

                  {/* #### CLIP DIALOG */}
                  <AudioDialog
                    isOpen={isClipDialogOpen}
                    onClose={() => {
                      setIsClipDialogOpen(false);
                      handleClearCuttingMark({ keepFirstEntry: true });
                    }}
                    title='Fazer download de conteúdo'
                    text="Você deseja fazer o download deste clip?"
                  >
                    <Button onClick={() => handleClearCuttingMark({ keepFirstEntry: true })} color="primary" variant="outlined">
                      Cancelar
                    </Button>
                    <Button onClick={handleConfirmClipgDownload} color="primary" autoFocus variant="contained">
                      OK
                    </Button>
                  </AudioDialog>
                  {/* #### SEARCH DIALOG */}
                  <AudioDialog
                    isOpen={searchConfirmationModal}
                    onClose={() => setSearchConfirmationModal(false)}
                    title="Fazer download de conteúdo"
                    text="Você deseja buscar esta data?"
                  >
                    <Button onClick={handleCancelSearchModal} color="primary" variant="outlined">
                      Cancelar
                    </Button>
                    <Button onClick={handleConfirmSearchModal} color="primary" autoFocus variant="contained">
                      OK
                    </Button>
                  </AudioDialog>
                  {/* #### CHANGE SROUCE ### */}
                  <AudioDialog 
                    isOpen={changeSourceModal}
                    onClose={() => setChangeSourceModal(false)}
                    title='Mudar praça'
                    text='Você deseja alterar praça?'
                  >
                    <Button onClick={handleCancelChangeSourceModal} color="primary" variant="outlined">
                      Cancelar
                    </Button>
                    <Button onClick={handleConfirmChangeSourceModal} color="primary" autoFocus variant="contained">
                      OK
                    </Button>
                  </AudioDialog>
                  {/* #### NOT FOUND ON LOAD CLIP ### */}
                </React.Fragment>
                )
              )
          }
        </PlayerArea>
      </PlayerLayout>
      </ThemeProvider>
      </div>
  )
};

export default AudioPlayer;