import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FSetSnackbarProps, Inputs } from "../../types";

import { Checkbox, CircularProgress, Divider, FormControlLabel, Grid, LinearProgress, MenuItem, TextField, Typography, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "../../../../contexts/snackbar";
import { useStoragedUserInfo } from "../../../../contexts/user";
import { axiosCallHandler } from "../../../../infra/axiosHelper";
import { retrieveFromStorage, saveToStorage } from "../../../../utils/local-storage-manager";
import * as S from "../components/UserForm/styles";
import { ClippingSourceProps } from "../components/UserForm/types";
import { CheckBoxContainer, SavingScreenLayer } from "../edit-user/styles";



export function CreateUser() {
  const { setUser } = useStoragedUserInfo();
  const snackbar = useSnackbar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: {
    username: '', email: '', password1: '', password2: '', nome: '', enabled: 'NÃO', role: 'ROLE_USER', rendering: 'VIDEO'
  } });

  const [isClippingSourceLoading, setIsClippingSourceLoading] = useState(true);
  const [indeterminate, setIndeterminate] = useState(false);
  const [formUserClippingSourceList, setFormUserClippingSourceList] = useState<Map<string, ClippingSourceProps>>(
    new Map()
  );
  let currentLabel = ''
  const [isSaving, setIsSaving] = useState(false);

  function setSnackbar({ status, type, message }: FSetSnackbarProps) { 
    snackbar.setStatus(status);
    snackbar.setType(type);
    snackbar.setMessage(message);
  }

  const useStyles = makeStyles((theme) => ({
    formControlLabel: {
      '&.Mui-disabled > span:nth-child(2)': {
        color: '#5f5f5f45'
      }
    },
  }));

  const classes = useStyles();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { username, password1, nome, email, role, rendering, enabled } = data;
    setIsSaving(true);
    axiosCallHandler({ method: 'POST', url: '/users-auth', data: {
      username,
      password: password1,
      name: nome, 
      email,
      role,
      rendering,
      enabled: enabled === 'SIM',
      groups: []
    } }).then((res) => {
      let userClippingSourceList: string[] = [];

      const listOfChangedSources = getFormUserClippingSourceAsArrayOfKeys().filter(key => {
        const element = formUserClippingSourceList.get(key);
  
        return element?.changed
      });

      new Promise((resolve, reject) => {
        if(listOfChangedSources.length === 0) {
          setTimeout(() => {
            resolve({  finished: true })
          }, 1000)
        }
        
        listOfChangedSources.forEach((key, idx) => {
          const element = formUserClippingSourceList.get(key)
  
          if(element?.checked){
            userClippingSourceList.push(key)
          }
  
          if (element?.checked && element?.changed) {
            axiosCallHandler({
              method: 'POST',
              url: `/users/${email}/groups/${element?.name}/bind`,
            })
            .then(() => {
              if(idx === listOfChangedSources.length - 1) {
                resolve({  finished: true });
              }
            })
              .catch((err) => {
                setSnackbar({ status: true, type: 'error', message: 'Houve um erro ao tentar salvar suas alterações' })
              });
          }
  
          if (!element?.checked && element?.changed) {
            axiosCallHandler({
              method: 'POST',
              url: `/users/${email}/groups/${element?.name}/unbind`,
            })
            .then(() => {
              if(idx === listOfChangedSources.length - 1) {
                resolve({  finished: true });
              }
            })
            .catch((err) => {
              setSnackbar({ status: true, type: 'error', message: 'Houve um erro ao tentar remover uma das opções' })
            });
          }
        });
      }).then(res => {
        setIsSaving(false);
        window.scrollTo({ top: 0, behavior: 'smooth' })
        saveToStorage("storagedUser", { ...data, enabled: data.enabled === 'SIM' });
      
        setUser({
          username,
          name: data.nome,
          email,
          password: password1,
          enabled: data.enabled === 'SIM',
          role,
          groups: userClippingSourceList,
          rendering
        })
        setSnackbar({ status: true, type: 'success', message: 'Usuário cadastrado com sucesso!' });
      })
    })
  }

  const handleChangeCheckbox = (event: any) => {
    const { value: name, checked } = event.target;
    setIndeterminate(false);

    let clipSource = formUserClippingSourceList.get(name);
    let newMap = new Map(formUserClippingSourceList);

    if(clipSource) {
      newMap.set(
        name,
        {
          ...clipSource,
          checked: checked,
          changed: true
        }
      )
    }

    setFormUserClippingSourceList(newMap)
  };

  function generateClippingSourceMapList(clipList: ClippingSourceProps[]) {
    let clipMapList = new Map<string, ClippingSourceProps>();

    for (let index = 0; index < clipList.length; index++) {
      let element = clipList[index];
      element.checked = false;
      element.changed = false;
      element.label = `${element.location_label} - ${element.channel_label}`

      clipMapList.set(element.name, element);
    }

    return clipMapList
  }

  function getFormUserClippingSourceAsArrayOfKeys() {
    return [...formUserClippingSourceList.keys()];
  }

  const getIndeterminatedCheckedStatus = () => {
    const isChecked = getFormUserClippingSourceAsArrayOfKeys().every(key => {
      const keyElement = formUserClippingSourceList.get(key);

      return keyElement?.checked === true || keyElement?.enabled === false
    })

    return isChecked;
  };

  const getIndeterminatedStatus = () => {
    let hasChecked = false;
    let hasNotChecked = false;

    getFormUserClippingSourceAsArrayOfKeys().some(key => {
      const element = formUserClippingSourceList.get(key);
      if(!element?.enabled) return;

      if(element?.checked){
        hasChecked = true;
      }else{
        hasNotChecked = true;
      }
    })

    return hasChecked && hasNotChecked;
  };

  const handleIndeterminateCheckbox = (event: any) => {
    const newClipMap = new Map(formUserClippingSourceList);

    getFormUserClippingSourceAsArrayOfKeys().forEach(key => {
      const element = formUserClippingSourceList.get(key);
      
      if(element){
        const isEnabled = element.enabled;

        newClipMap.set(key, {
          ...element,
          checked: isEnabled ? event.target.checked : false,
          changed: isEnabled ? true : false,
          enabled: isEnabled
        })
      }
    })

    setFormUserClippingSourceList(newClipMap)
    setIndeterminate(event.target.checked);
  };

  function generateSortedMapList(mapList: Map<string, ClippingSourceProps>) {
    let sortedMapList = new Map([...mapList].sort(([k, v], [k2, v2]) => {
      if (v.location_label[0] > v2.location_label[0]) {
        return 1;
      }
      if (v.location_label[0] < v2.location_label[0]) {
        return -1;
      }
      return 0; 
    }));

    return sortedMapList;
  }

  useEffect(() => {
    const clippingSourceList = retrieveFromStorage("clippingSourceList");

    //Se já houver uma lista salva no localStorage então use-a
    // if(clippingSourceList) {
    //   const parsedClippingSourceList = JSON.parse(clippingSourceList);

    //   let newMappedList = generateClippingSourceMapList(parsedClippingSourceList);

    //   let sortedMapList = generateSortedMapList(newMappedList);

    //   setFormUserClippingSourceList(sortedMapList);
    //   setIsClippingSourceLoading(false);

    //   return;
    // }

    axiosCallHandler({ method: 'GET', url: '/clipping-sources' }).then(
      (clippingSourceList: ClippingSourceProps[]) => {
        console.log(clippingSourceList)
        saveToStorage("clippingSourceList", clippingSourceList);

        let newMappedList = generateClippingSourceMapList(clippingSourceList);
        let sortedMapList = generateSortedMapList(newMappedList);
        
        setFormUserClippingSourceList(sortedMapList)
        setIsClippingSourceLoading(false);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      {isSaving && 
        (
          <SavingScreenLayer>
            <div>
              <CircularProgress size={65} color="primary" />
              <Typography variant="h5" >Salvando alterações</Typography>
            </div>
          </SavingScreenLayer>
        )
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.NameEmailWrapper>
          <Controller
            control={control}
            name="nome"
            rules={{ required: true}}
            render={({ field }) => (
              <S.StyledTextField
                variant="outlined"
                label="Nome"
                $hasError={errors.nome}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )} 
          />

          <Controller
            control={control}
            name="email"
            rules={{ required: true}}
            render={({ field }) => (
              <S.StyledTextField
                variant="outlined"
                label="Email"
                $hasError={errors.email}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )} 
          />
        </S.NameEmailWrapper>
        <Divider />
        <S.UserInfoWrapper>
          <Controller
            name="username"
            control={control}
            rules={{ required: true}}
            render={({ field }) => (
              <S.StyledTextField
                $gridArea={field.name}
                variant="outlined"
                label="Usuário"
                $hasError={errors.username}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )} 
          /> 
          <Controller
            name="password1"
            control={control}
            rules={{ required: true, minLength: 5 }}
            render={({ field }) => (
              <S.StyledTextField
                $gridArea={field.name}
                variant="outlined"
                label="Senha"
                $hasError={errors.password1}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )} 
          />
          <Controller
            name="password2"
            control={control}
            rules={{ required: true, minLength: 5 }}
            render={({ field }) => (
              <S.StyledTextField
                $gridArea={field.name}
                variant="outlined"
                label="Confirmar senha"
                $hasError={errors.password2}
                InputLabelProps={{ shrink: true }}
                {...field}
              />
            )} 
          />  
        </S.UserInfoWrapper>
        <Divider />
        <S.UserStateWrapper>
          <Controller
            name="role"
            control={control}
            rules={{ required: true}}
            defaultValue="ROLE_USER"
            render={({ field }) => (
              <TextField
                select
                label="Cargo"
                variant="outlined"
                {...field}
              >
                <MenuItem
                  key="ROLE_USER"
                  value="ROLE_USER"
                >
                  USER
                </MenuItem>
                <MenuItem
                  key="ROLE_ADMIN"
                  value="ROLE_ADMIN"
                >
                  ADMIN
                </MenuItem>
              </TextField>
            )}
          />

          <Controller
            name="enabled"
            control={control}
            defaultValue="SIM"
            render={({ field }) => (
              <TextField
                select
                label="Ativo"
                variant="outlined"
                {...field}
              >
                <MenuItem
                  key="ACTIVE"
                  value="SIM"
                >
                  SIM
                </MenuItem>
                <MenuItem
                  key="INACTIVE"
                  value="NÃO"
                >
                  NÃO
                </MenuItem>
              </TextField>
            )} 
          />
          
          <Controller
            name="rendering"
            control={control}
            defaultValue="VIDEO"
            render={({ field }) => (
              <TextField
                select
                label="Rendering"
                variant="outlined"
                {...field}
              >
                <MenuItem
                  key="VIDEO"
                  value="VIDEO"
                >
                  Vídeo
                </MenuItem>
                <MenuItem
                  key="AUDIO"
                  value="AUDIO"
                >
                  Audio
                </MenuItem>
              </TextField>
            )} 
          />
          
          
        </S.UserStateWrapper>
        <Divider />

        
        {
          isClippingSourceLoading
          ?
          (
            <LinearProgress />
          )
          :
          (
            <Fragment>
               <FormControlLabel
                label="Selecionar todos"
                name="select-all"
                value={indeterminate}
                control={
                  <Checkbox
                    name="select-all"
                    color="primary"
                    checked={getIndeterminatedCheckedStatus()}
                    indeterminate={getIndeterminatedStatus()}
                  />
                }
                onChange={(event: any) => handleIndeterminateCheckbox(event)}
              />
              <CheckBoxContainer container spacing={2}>
                {
                  getFormUserClippingSourceAsArrayOfKeys().map(key => {
                    let mapCurrentLabel = formUserClippingSourceList.get(key)?.location_label[0].toUpperCase();
                    let element = formUserClippingSourceList.get(key);

                    if(mapCurrentLabel !== currentLabel){
                      currentLabel = mapCurrentLabel ?? '';

                      return (
                        <Fragment key={element?.name}>
                          <Grid item lg={12} md={12} xs={12}>
                            <Typography style={{ fontSize: 55, marginTop: 25 }}>{mapCurrentLabel}</Typography>
                          </Grid>
                          <Grid item md={4} sm={6} xs={12} lg={3}>
                            <FormControlLabel
                              className={classes.formControlLabel}
                              key={element?.name}
                              name={element?.label}
                              checked={element?.checked && element?.enabled}
                              disabled={!element?.enabled}
                              label={element?.label}
                              value={element?.name}
                              control={
                                <Checkbox name={element?.label} color="primary" id={element?.name} />
                              }
                              onChange={(event: any) => handleChangeCheckbox(event)}
                            />
                          </Grid>
                        </Fragment>
                      )
                    }

                    return (
                      <Grid item md={4} sm={6} xs={12} lg={3} key={element?.name}>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          name={element?.label}
                          checked={element?.checked && element?.enabled}
                          disabled={!element?.enabled}
                          label={element?.label}
                          value={element?.name}
                          control={
                            <Checkbox name={element?.label} color="primary" id={element?.name} key={element?.name} />
                          }
                          onChange={(event: any) => handleChangeCheckbox(event)}
                        />
                      </Grid>
                    )
                  })
                }
              </CheckBoxContainer>
            </Fragment>
          )
        }

        
        <S.StyledButton type="submit">Salvar</S.StyledButton>
      </form>
    </S.Container>
  );
};