import {
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Ref, useState } from 'react';
import { InternalTooltip } from '../../../../components/Tooltip';

import * as S from './player.styles';

type NameAndLabel = { name?: string; label?: string };

interface PlayerProps {
  location: NameAndLabel;
  channel: NameAndLabel & { inputValue: string };
  finalMixedDate: Date;
  timeStamp: number;
  IframeRef: Ref<HTMLIFrameElement>;
  inputStartTime: Date;
  inputDateAndTime: Date;
  src: any;
}

const Player = ({
  location,
  channel,
  finalMixedDate,
  timeStamp,
  IframeRef,
  inputStartTime,
  inputDateAndTime,
  src,
}: PlayerProps) => {
  const textQueryMatches = useMediaQuery('(max-width: 899px)');

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleToggleTooltip = () => setIsTooltipOpen((prevState) => !prevState);

  const HtmlTooltip = withStyles((theme: any) => ({
    arrow: {
      color: '#f5f5f9',
    },
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      width: '100%',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const handleTextToDisplay = () => {
    if (!!textQueryMatches)
      return 'Procure o vídeo utilizando o formulário acima!';
    return 'Procure o vídeo utilizando o formulário ao lado!';
  };

  return src ? (
    <S.StyledIframeContainer id="iframe-container">
      <S.StyledIframe
        title={`${channel?.label} vídeo`}
        srcDoc={src}
        frameBorder="0"
        ref={IframeRef}
      />
      <HtmlTooltip
        open={isTooltipOpen}
        onClose={handleToggleTooltip}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        arrow
        placement="left-start"
        style={{ position: 'absolute', right: '-40px' }}
        title={
          <InternalTooltip
            location={location.label}
            channel={channel.inputValue}
            schedule={inputDateAndTime}
            initialDate={inputStartTime}
            timeLapse={timeStamp}
          />
        }
      >
        <IconButton aria-label="info">
          <InfoIcon />
        </IconButton>
      </HtmlTooltip>
    </S.StyledIframeContainer>
  ) : (
    <S.StyledBox>
      <Typography>{handleTextToDisplay()}</Typography>
    </S.StyledBox>
  );
};

export default Player;
