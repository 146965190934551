import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;

  > hr {
    margin: 2rem;
    background-color: #C5C0C0;
  }

  @media (max-width: 880px) {
    > hr {
      margin: 2rem 1rem;
    }

    > header {
      padding: 1rem 1rem 0 1rem;
    }

    > header > div {
      max-width: 100%;
      width: 100%;
      margin: 0rem;
    }
  }
`;

export const Header = styled.header`
  > div {
    max-width: 20rem;
    margin: 2rem 0 0rem 2rem;
  }
`;

export const Main = styled.main``;

export const CheckBoxContainer = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const SavingScreenLayer = styled.span`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: #2f2f2fab;
  display: grid;
  place-items: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h5 {
    animation: pulse .5s infinite alternate;
  }

  @keyframes pulse {
    0% {
      color: #FFF;
    }
    100% {
      color: #CCC;
    }
  }
`;