import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: flex-start;

    p {
      padding-left: 5px;
      color: #515151;
    }
  }
`;

export const Icon = styled.img`
  width: 20px;
`;