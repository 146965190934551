import { useState } from 'react';
import { KeyboardTimePicker } from './styles';

type KeyboardTimePickerProps = {
  startDate: Date;
  handleStartDate: (date: Date) => void;
  endDate: Date;
  handleEndDate: (date: Date) => void;
};

const TimePicker = ({
  startDate,
  handleStartDate,
  endDate,
  handleEndDate,
}: KeyboardTimePickerProps) => {
  const [startDatePicker, setStartDatePicker] = useState(new Date(startDate));
  const [endDatePicker, setEndDatePicker] = useState(
    new Date(startDate?.getTime() + 60000)
  );

  const handleChangeStartTime = (updatedDate: Date) => {
    setStartDatePicker(updatedDate);
  };

  const handleChangeEndDate = (date: Date) => {
    setEndDatePicker(date);
    handleEndDate(date);
  }

  const handleApplyDatesOnBlurEffect = () => {
    const endDate = new Date(startDatePicker.getTime() + 60000);
    
    setEndDatePicker(endDate);
    handleStartDate(startDatePicker);
    handleEndDate(endDate);
  };

  return (
    <>
      <KeyboardTimePicker
        inputVariant="outlined"
        label="Horário de início"
        placeholder="08:00:00"
        ampm={false}
        format="HH:mm:ss"
        value={startDatePicker}
        onChange={(date: any) => handleChangeStartTime(date)}
        open={false}
        keyboardIcon={null}
        onBlur={handleApplyDatesOnBlurEffect}
      />

      <KeyboardTimePicker
        inputVariant="outlined"
        label="Horário de fim"
        placeholder="08:00:00"
        ampm={false}
        format="HH:mm:ss"
        value={endDatePicker}
        onChange={(date: any) => handleChangeEndDate(date)}
        open={false}
        keyboardIcon={null}
      />
    </>
  );
};

export default TimePicker;
