import Axios from 'axios';

import { setupInterceptorsTo } from '../utils/axios-interceptors';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CancelToken = Axios.CancelToken;

const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.defaults.timeout = 120000;

setupInterceptorsTo(axios);

export default axios;
