import styled, { css } from "styled-components";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
 
  form {
    > div:nth-child(5){
      div {
        max-width: 9rem;
  
        @media(max-width: 800px){
          max-width: 100%;
        }
      }
    }

    > button {
      margin: 1rem 0;
      background-color: #0D3EFB;
      color: #fff;
      padding: 0.3rem 1.5rem;
      max-width: 10rem;
      width: 100%;

      :hover, :focus {
        background-color: #3C2EE3;
      }

      :active {
        background-color: #223B6B;
      }

      @media(max-width: 730px){
        max-width: 100%;
      }
    }

    section, >div {
      display: flex;
      gap: 1rem;
    }

    @media (max-width: 800px){
      div {
        max-width: 100%;
      }

      >section {
        flex-direction: column;

        div { 
          max-width: 100%;
          margin: 0;
        }

        div:first-child {
          margin: 2rem 1rem 0 0;
        }

        div:last-child {
          margin: 0 0 1rem 0;
        }
      }
    }

    @media(max-width: 599px){
      > div {
        width: 100%
      }

      button:last-child{
        max-width: 100%;
      }
    }
  }
`;

function disableEffect () {
  return css`
    pointer-events: none;

    input,label {
      color: #e2e1e1;
    }

    fieldset{
      border-color: #e2e1e1;
    }
  `
}

export const StyledTextField = styled(TextField) <{ $isDisabled?: boolean }>`
  max-width: 20rem;
  width: 100%;

  ${props => props.$isDisabled && disableEffect()}

  && {
    margin: 2rem 0;
  }
`;

export const StyledAutoComplete = styled(Autocomplete)`
  max-width: 9rem;
  width: 100%;
`;