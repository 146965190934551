import styled from 'styled-components';

export const CardRoot = styled.div<{ $isFocused: boolean }>`
  border: 1px solid #948f8f;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s linear;

  background-image: ${props => props.$isFocused && 'linear-gradient(80deg,#4875FB,#20B9FC)'};

  * {
    fill: ${props => props.$isFocused && '#fff'};
    color: ${props => props.$isFocused && '#fff'};
  }

  h5, span {
    color: ${props => props.$isFocused && '#fff'};
  }

  :hover {
    background-image: linear-gradient(80deg,#4875FB,#20B9FC);
    h5, span {
      color: ${props => props.$isFocused && '#fff'};
    }

    * {
      color: #fff;
      fill: #fff;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5;
  padding: 2px;

  > div {
    padding: 10px;
  }

  /* :hover {
    * {
      color: #fff;
      fill: #fff;
    }
  } */
`;