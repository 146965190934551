import { CancelTokenSource } from 'axios';

import api from '../services/api';

type UserProps = {
  username: string;
  password: string;
};

type UpdateTokenAndRefreshProps = {
  username?: string;
  token?: string;
  refresh?: string;
  cancelToken: CancelTokenSource;
};

export const GetToken = async ({ username, password }: UserProps) => {
  try {
    const response = await api.post(`/auth/token`, { username, password });
    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const GetUpdatedTokenAndRefresh = async ({
  token,
  refresh,
  username,
  cancelToken,
}: UpdateTokenAndRefreshProps) => {
  try {
    return await api.post(
      '/auth/refresh',
      {
        username,
        token,
        refresh,
      },
      { cancelToken: cancelToken.token }
    );
  } catch (err) {
    console.error(err);
    return null;
  }
};
