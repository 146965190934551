import { OutlinedInput } from '@material-ui/core';
import { KeyboardTimePicker as TimePicker } from '@material-ui/pickers';
import styled, { css } from "styled-components";

const setInvalidFieldCss = css<{ isDateInvalid: boolean }>`

  fieldset {
    border-color: ${props => props.isDateInvalid && 'red'};
  }

  input {
    color: ${props => props.isDateInvalid && '#e52a2a'};
  }
`;

export const KeyboardTimePicker = styled(TimePicker)`
  width: 90%;
  
  :focus-within {
    border-color: #0D3EFB;
    box-shadow: none;
    color: red;

    > div {
      border-color: #0D3EFB;
    }
  }

  input {
    padding: 18.5px 14px;
  }

  button {
    display: none;
  }
`;

export const StyledLabel = styled.label<{ isDateInvalid: boolean }>`
  color: ${props => props.isDateInvalid && 'red'};
`;

export const StyledTimePicker = styled(OutlinedInput) <{ isDateInvalid: boolean }>`
    width: 100%; 
    border: none;
    border-radius: 3px;
    
    input {
      color: #333333;
      font-size: 1rem;
    }

    fieldset {
      border: 1px solid #A2A7AC;
    }

    ${setInvalidFieldCss}

    :hover {
      border-color: #787474;
    }

    :focus-within {
      border-color: #0D3EFB;
      box-shadow: none;
    }
`;

export const StyledTimePickerLabelContainer = styled.div`
  position: relative;
  width: 90%;

  :focus-within label{
    color: #0D3EFB;
  }

  label { 
    font-size: .75rem;
    position: absolute;
    z-index: 10;
    padding: 0 6px;
    background-color: #fff;
    left: 7px;
    top: -9px;
  }
`;