import { Dialog, DialogContentText } from "@material-ui/core";
import { ReactNode } from "react";
import { DialogActions, DialogContent, DialogTitle } from "./styles";

type AudioDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  text: string;
  children: ReactNode;
}

const AudioDialog = ({ children, isOpen, onClose, text, title }: AudioDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {children}
    </DialogActions>
  </Dialog>
  );
};

export default AudioDialog;