export const mockedDuration = [
  { name: '30', label: '30 segundos' },
  { name: '60', label: '1 minuto' },
  { name: '90', label: '1 minuto e meio' },
  { name: '120', label: '2 minutos' },
  { name: '180', label: '3 minutos' },
  { name: '240', label: '4 minutos' },
  { name: '300', label: '5 minutos' },
];

export const initialUserData = {
  username: '',
  email: '',
  name: '',
  enabled: false,
  groups: [],
  role: ''
};
