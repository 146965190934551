const recorderStorageKeys = {
  showUsers: 'show-users',
  createUsers: 'create-user',
  editUsers: 'edit-user', //editar usuarios provavelmente vai sumir por irá mesclar com listar (a página não a feature)
  createPlaza: 'create-plaza',
  createGroup: 'create-group',
  currentPage: 'current-page',
  clippingSourceList: '@recorder/clipping-source-list',
  storagedUser: '@recorder/storaged-user',
  lastVisitedRoute: '@recorder/last-visited-route',
  lastEditedUser: '@recorder/last-edited-user'
}

type StorageKeys = keyof typeof recorderStorageKeys;

export const saveToStorage = (key: StorageKeys, value: unknown) => {
  try {
    localStorage.setItem(recorderStorageKeys[key], JSON.stringify(value));
  } catch (err) {
    console.warn('Não foi possível salvar o caminho selecionado');
  }
};

export const retrieveFromStorage = (key: StorageKeys) => {
  try {
    return localStorage.getItem(recorderStorageKeys[key]);
  } catch (err) {
    console.warn('Não foi possível carregar o item da storage');
  }
}

export const deleteFromStorage = (key: StorageKeys) => {
  try {
    localStorage.removeItem(recorderStorageKeys[key]);
  } catch (err) {
    console.log('Não foi possível remover o item do storage')
  }
}