import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

type DialogProps = {
  dialogStatus: boolean;
  setDialogStatus: (state: boolean) => void;
  dialogText?: string;
  setDialogText: (text: string) => void;
};

const DialogContext = createContext({} as DialogProps);

export const DialogProvider = ({ children }: { children?: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const dialog = useMemo(
    () => ({
      dialogStatus: isOpen,
      dialogText: dialogText,
      setDialogText,
      setDialogStatus: setIsOpen,
    }),
    [dialogText, isOpen]
  );

  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);

export default DialogContext;
