import { Dispatch, memo, SetStateAction } from 'react';
import { StyledAutoComplete, StyledTextField } from './styles';

interface AutoProps<T> {
  label: string;
  options: Array<T>;
  value: T;
  setValue: Dispatch<SetStateAction<any>>;
  nameType?: boolean;
  setInputValue?: (value: string) => void;
  gridArea?: string;
}
function AutoComplete<T extends {}>({
  label,
  options,
  value,
  setValue,
  nameType = false,
  setInputValue,
  gridArea,
}: AutoProps<T>) {
  return (
    <StyledAutoComplete
      $gridArea={gridArea}
      autoComplete={false}
      clearOnBlur={false}
      options={options}
      value={value}
      onChange={(event: any, newValue: any) => {
        setValue(newValue);
      }}
      getOptionLabel={(data: any) => {
        return nameType ? data?.name : data?.label;
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.name === value.name || value.name === '';
      }}
      renderInput={(params) => (
        <StyledTextField {...params} label={label} variant="outlined" />
      )}
      onInputChange={(event, value) => {
        if (setInputValue) {
          setInputValue(value);
        }
      }}
    />
  );
}

export default memo(AutoComplete);
