import EventIcon from '../../assets/event.svg';
import LocationIcon from '../../assets/location.svg';
import ScheduleIcon from '../../assets/schedule.svg';
import TimelapseIcon from '../../assets/timelapse.svg';
import TvIcon from '../../assets/tv.svg';
import { fromSecondsToLocaleTimeString } from '../../utils/date-time';
import { Container, Icon } from './styles';

export function InternalTooltip({
  location,
  channel,
  schedule,
  initialDate,
  timeLapse,
}: any) {
  return (
    <Container>
      <div>
        <Icon src={LocationIcon} alt="Location" />
        <p>{location}</p>
      </div>
      <div>
        <Icon src={TvIcon} alt="TV Source" />
        <p>{channel}</p>
      </div>
      <div>
        <Icon src={EventIcon} alt="Calendar" />
        <p>{schedule.toLocaleDateString()}</p>
      </div>
      <div>
        <Icon src={ScheduleIcon} alt="Schedule time" />
        <p>{initialDate.toLocaleTimeString()}</p>
      </div>
      <div>
        <Icon src={TimelapseIcon} alt="Chronometer" />
        <p>{fromSecondsToLocaleTimeString(timeLapse)}</p>
      </div>
    </Container>
  );
}
