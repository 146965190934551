import {
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';

import { useSnackbar } from '../../../../contexts/snackbar';
import { useStoragedUserInfo } from '../../../../contexts/user';
import axios from '../../../../services/api';
import { deleteFromStorage, saveToStorage } from '../../../../utils/local-storage-manager';
import { FSetSnackbarProps, UsersProps } from '../../types';
import Search from '../components/Search';
import * as S from './styles';

export function UsersList() {
  const { setUser } = useStoragedUserInfo();
  const snackbar = useSnackbar();
  const [usersList, setUsersList] = useState<Array<UsersProps>>([]);
  const [selectedUser, setSelectedUser] = useState<UsersProps | null>(null);
  const [pageControl, setPageControl] = useState({
    current: 0,
    total: 0,
    rowsPerPage: 10,
  });
  const [nextPageIsDisabled, setNextPageIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  function setSnackbar({ status, type, message }: FSetSnackbarProps) { 
    snackbar.setStatus(status);
    snackbar.setType(type);
    snackbar.setMessage(message);
  }

  const convertBooleanToString = (isEnabled: boolean) => {
    return isEnabled ? 'Sim' : 'Não';
  };

  const userRoleAdapter = (userRole: string) => {
    return userRole === 'ROLE_ADMIN' ? 'Administrador' : 'Usuário';
  };

  const handleNavigateToEditPageWithUserData = (userData: UsersProps) => {
    history.push({ pathname: '/admin/edit-user', state: userData }); //Remover após conclusão
    setUser(userData);
    saveToStorage('lastEditedUser', userData);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/users?page=${pageControl.current}&size=${pageControl.rowsPerPage}`)
      .then((res) => {
        setUsersList(res.data.content);
        setNextPageIsDisabled(false);
        setIsLoading(false);

        if (pageControl.total === res.data.totalPages) return;
        if (res.data.totalPages === pageControl.current + 1)
          setNextPageIsDisabled(true);
      })
      .catch((err) => {
        setSnackbar({
          status: true,
          message: 'Houve um erro na chamada',
          type: 'error',
        });
      });

      setUser({
        username: '',
        email: '',
        name: '',
        enabled: false,
        rendering: 'VIDEO',
        password: '',
        role: 'ROLE_USER',
        groups: [],
      });
      
      deleteFromStorage('storagedUser');
  }, [pageControl]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageControl((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
      current: 0,
    }));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageControl((prevState) => ({
      ...prevState,
      current: newPage,
    }));
  };

  if (isLoading) {
    return (
      <S.Loading>
        <div></div>
        <div></div>
      </S.Loading>
    );
  }

  return (
    <S.StyledTableContainer component={Paper}>
      <section>
        <Search
          isSearchInput
          userList={usersList}
          setUserList={setUsersList}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </section>
      <S.StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Nome</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Usuário</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">E-mail</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Ativo</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">Cargo</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersList.map((user) => (
            <TableRow key={user.email}>
              <TableCell data-label="Nome" align="left">
                <Typography variant="subtitle2">{user.name}</Typography>
              </TableCell>
              <TableCell data-label="Usuário" align="left">
                <Typography variant="subtitle2">{user.username}</Typography>
              </TableCell>
              <TableCell data-label="E-mail" align="left">
                <Typography variant="subtitle2">{user.email}</Typography>
              </TableCell>
              <TableCell data-label="Ativo" align="left">
                <Typography variant="subtitle2">
                  {convertBooleanToString(user.enabled)}
                </Typography>
              </TableCell>
              <TableCell data-label="Cargo" align="left">
                <Typography variant="subtitle2">
                  {userRoleAdapter(user.role)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <EditIcon
                  onClick={() => handleNavigateToEditPageWithUserData(user)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </S.StyledTable>
      <TablePagination
        component="div"
        count={100}
        page={pageControl.current}
        rowsPerPage={pageControl.rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={{
          disabled: pageControl.current === 0,
          style: {
            marginRight: 20,
          },
        }}
        nextIconButtonProps={{
          disabled: nextPageIsDisabled,
        }}
        style={{
          margin: '10px 10px 0 0',
        }}
      />
    </S.StyledTableContainer>
  );
}
