type Action = {
  type: 'SET_LIST' | 'SET_CURRENT' | 'SET_LABEL' | 'SET_NAME' | 'CLEAR_ALL';
  payload?: any;
};

export type StateProps = {
  list: Array<{ name: string, label: string; }>,
  current: { name: string, label: string; },
  label: string;
  name: string;
};

export const Reducer = (state: StateProps, action: Action) => {
  switch (action.type) {
    case 'SET_LIST':
      return { ...state, list: action.payload };
    case 'SET_CURRENT':
      return { ...state, current: action.payload };
    case 'SET_LABEL':
      return { ...state, label: action.payload };
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'CLEAR_ALL':
      return { ...state, current: { label: '', name: '' }, label: '', name: '' }

    default:
      return state;
  };
};