import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Dispatch, ReactNode, SetStateAction } from 'react';

type FeedbackType = 'success' | 'warning' | 'error' | 'info';

type SnackBarProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dispatch?: any;
  feedbackType: FeedbackType;
  children: ReactNode;
};

const SnackBar = ({ children, open, setOpen, feedbackType, dispatch }: SnackBarProps) => {
  const handleClose = () => {
    setOpen(false);
    
    if(dispatch) {
      dispatch();
    }
  }

  const handleSelfClose = () => {
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  if (open) {
    handleSelfClose();
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert onClose={handleClose} severity={feedbackType}>
        {children}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackBar;
