import axios from "../services/api";

interface RequestDataProps {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  data?: Object;
}

export const axiosCallHandler = async ({ method, url, data }: RequestDataProps) => {
  switch (method) {
    case "GET":
      try {
        const res = await axios.get(url);
        return res.data;
      } catch (err: any) {
        if (err.response.status === 401 || err.response.status === 403) {
          return { redirect: true }
        }
        break;
      }
    case "POST":
      try {
        const res = await axios.post(url, data);
        return res.data;
      } catch (err: any) {
        if (err.response.status === 401 || err.response.status === 403) {
          return { redirect: true }
        }
        break;
      }
    case "DELETE":
      try {
        const res = await axios.delete(url);
        return res.data;
      } catch (err: any) {
        if (err.response.status === 401 || err.response.status === 403) {
          return { redirect: true }
        }
        break;
      }
    case "PATCH":
      try {
        const res = await axios.patch(url);
        return res.data;
      } catch (err: any) {
        if (err.response.status === 401 || err.response.status === 403) {
          return { redirect: true }
        }
        break;
      }
    default:
      return { message: `${method} not implemented` };
  }
}