import styled from 'styled-components';

import { TextField } from '@material-ui/core';
import AutoComplete from '@material-ui/lab/Autocomplete';

export const StyledAutoComplete = styled(AutoComplete)`
  width: 90%;

  & .MuiInputBase-input {
    color: #333333;
  }
`;

export const StyledTextField = styled(TextField)`
  margin-left: 20px;
`;
