import { OutlinedInput } from "@material-ui/core";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

type Props = {
  value: string;
  placeholder: string;
  onChange: Dispatch<SetStateAction<string>>;
  id: string;
}

const Filter = ({ value, onChange, ...rest }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }
  return (
    <OutlinedInput value={value} onChange={handleChange} {...rest} />
  )
}

export default Filter;