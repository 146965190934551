import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import DateFnsUtils from '@date-io/date-fns';

import { useAuth } from '../../contexts/auth';

import api, { CancelToken } from '../../services/api';

import GloboLogo from '../../assets/globo-logo.png';
import CustomField from '../../components/CustomField';
import DefaultButton from '../../components/DefaultButton';
import Dialog from '../../components/Dialog';
import useGetIframeSrc from './components/Player/hooks/useGetIframeSrc';

import { TextField, Typography } from '@material-ui/core';
import {
  getInitialTime,
  getTimeDifferenceBetweenDates,
  isTimeDifferenceGreaterThanTenMinutes,
  isTimeObjectValueEqual,
  mixDates,
  validateDateAndTime,
  validateFutureDate,
} from '../../utils/date-time';
import { initialUserData } from './mock';

import * as S from './styles';

import { LocationAndChannel, UserData } from './types';

import { Autocomplete } from '@material-ui/lab';
import TimePicker from '../../components/TimerPicker';
import { useDialog } from '../../contexts/dialog';
import { isGloboCurrentApp } from '../../utils/get-app-env';
import PlayerLayout from '../layout/player-layout';
import Player from './components/Player/player';

const PlayerPage = () => {
  const Auth = useAuth();
  const mediaRef = useRef<any>();
  const IframeRef = useRef<any>();

  const [userData, setUserData] = useState<UserData>(initialUserData);

  const [userLocation, setUserLocation] = useState([]);
  const [locationValue, setLocationValue] = useState<LocationAndChannel>(null);
  const [locationInputValue, setLocationInputValue] = useState('');

  const [userChannel, setUserChannel] = useState<any>([]);

  const [channelValue, setChannelValue] = useState<LocationAndChannel>(null);
  const [channelInputValue, setChannelInputValue] = useState('');

  const mockedData = useMemo(() => getInitialTime(), []);

  const [inputStartTime, setInputStartTime] = useState<Date | null | any>(
    mockedData
  );

  const [inputEndTime, setInputEndTime] = useState<Date | null | any>(
    new Date(inputStartTime?.getTime() + 60000)
  );

  const [inputDateAndTime, setInputDateAndTime] = useState<Date | null | any>(
    mockedData
  );

  const { timeStamp, validateTimeStamp } = getTimeDifferenceBetweenDates(
    inputEndTime,
    inputStartTime
  );

  const [isTimeValid] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [iframeSrc, setIframeSrc]: any = useState();
  const [isLoadingIframe, setIsLoadingIframe] = useState(false);

  const dialog = useDialog();

  const finalMixedDate = mixDates(inputDateAndTime, inputStartTime);

  const { src, handleGetSrc, isLoading } = useGetIframeSrc({
    finalMixedDate,
    timeStamp,
    videoGroupData: {
      location: {
        name: locationValue?.name,
      },
      channel: {
        name: channelValue?.name,
      },
    },
  });

  useEffect(() => {
    if (iframeSrc) {
      mediaRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [isLoadingIframe, iframeSrc]);

  const handleValidateTimeObjectAndStopSubmitFlowIfPass = () => {
    //declarations
    const isTimeEqualOrLessOneSecond = isTimeObjectValueEqual(
      inputStartTime,
      inputEndTime
    );
    const isTimeGreaterThanTenMinutes = isTimeDifferenceGreaterThanTenMinutes(
      inputStartTime,
      inputEndTime
    );

    //If section
    if (isTimeEqualOrLessOneSecond) {
      //if date are equals, shows error dialog
      dialog.setDialogStatus(true);
      dialog.setDialogText(
        'Não é possível pesquisar vídeos com menos de 1 segundo de duração.'
      );
      return true;
    }

    if(validateFutureDate(inputDateAndTime)) {
      dialog.setDialogStatus(true);
      dialog.setDialogText(
        'Ups, parece que está procurando por um trecho no futuro. Por favor, conferir novamente data e hora.'
      );
      return true;
    }

    if (isTimeGreaterThanTenMinutes) {
      //if you're trying to search with more than 10 minutes, shows error dialog
      dialog.setDialogStatus(true);
      dialog.setDialogText(
        'Não é possível pesquisar vídeos com mais de 10 minutos de duração.'
      );
      return true;
    }

    const { dateValidation } = validateDateAndTime(inputDateAndTime);

    setIsLoadingIframe(true);

    if (dateValidation && !validateTimeStamp) {
      dialog.setDialogStatus(true);
      dialog.setDialogText(
        'Ups, parece que está procurando por um trecho no futuro. Por favor, conferir novamente data e hora.'
      );
      return true;
    } 

    handleGetSrc();

    setIsLoadingIframe(false);
    return false;
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (handleValidateTimeObjectAndStopSubmitFlowIfPass()) {
      return;
    }
  };

  const handleDateChange = (date: Date | null) => {
    const selectedDate = date?.getDate();
    const selectedMonth = date?.getMonth();

    inputEndTime.setDate(selectedDate);
    inputEndTime.setMonth(selectedMonth);

    inputStartTime.setDate(selectedDate);
    inputStartTime.setMonth(selectedMonth);

    setInputDateAndTime(date);
  };

  const memoizeValidateInput = useCallback(() => {
    !locationValue ||
    !channelValue ||
    !inputEndTime ||
    !inputDateAndTime ||
    inputDateAndTime?.toString().includes('Invalid Date') ||
    !isTimeValid
      ? setIsDisabled(true)
      : setIsDisabled(false);
  }, [
    locationValue,
    channelValue,
    inputEndTime,
    inputDateAndTime,
    isTimeValid,
  ]);

  useEffect(() => {
    memoizeValidateInput();
  }, [memoizeValidateInput]);

  useEffect(() => {
    const handleGetUserByUsername = async () => {
      const StoredUserData = Auth.sessionData;

      try {
        const response = await api.get(`/users/${StoredUserData?.username}`);
        setUserData(response.data);
      } catch (err) {
        console.error(err);
        Auth.logout();
      }
    };
    handleGetUserByUsername();
  }, [Auth]);

  const handleGetCanais = async (locationName: string) => {
    const source = CancelToken.source();

    if(!locationName) return;

    try {
      const dataToBeUsedAsParams = userData.groups.join(',');
      const { data } = await api.get<Array<{id: number, name: string, label: string}>>(
        `/locations/${locationName}/channels?clipping_sources=${dataToBeUsedAsParams}`,
        {
          cancelToken: source.token,
        }
      );

      let sortedChannels: Array<LocationAndChannel> = data.sort((prev, next) => prev.name > next.name ? 1 : -1);
      setUserChannel(sortedChannels);

      let globo = sortedChannels.find(channel => channel?.name === "GLO");

      //Caso seja env globo, o channel esteja vazio e exista o channel globo na lista de channels do usuário, globo será selecionado auto.
      if(isGloboCurrentApp() && !channelValue && globo ){
        setChannelValue(globo);
      }else{
        setChannelValue(sortedChannels[0]);
      }

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleGetPracas = async () => {
      const dataToBeUsedAsParams = userData.groups.join(',');

      try {
        //locations?clipping_sources está na função de utils
        const { data } = await api.get(
          `/locations?clipping_sources=${dataToBeUsedAsParams}`
        );
        setUserLocation(data);
      } catch (err) {
        console.error(err);
      }
    };

    handleGetPracas();
  }, [userData]);

  const handleConvertNameAndLabel = useMemo(
    () => (options: any) => {
      return options.map((item: any) => ({
        ...item,
        nameLabel: `${item.name} - ${item.label}`,
      }));
    },
    []
  );

  const shouldRenderGloboContent = () => {
    if (!isGloboCurrentApp()) {
      return;
    }

    return (
      <S.StyledHeaderContent>
        <S.GloboIcon src={GloboLogo} alt="globo logo" />
        <Typography variant="h6">
          Sistema de captura de conteúdo para uso exclusivo dos canais e áreas
          da Globo
        </Typography>
      </S.StyledHeaderContent>
    );
  };

  const handleRenderGloboAutocomplete = () => {
    if(isGloboCurrentApp()){
        return <CustomField
        options={userChannel}
        inputLabel="Escolher o canal"
        userValue={channelValue}
        setUserValue={setChannelValue}
        textValue={channelInputValue}
        setTextValue={setChannelInputValue}
        noOptionsText="Escolher antes uma praça"
      />
    }

    return (
      <div style={{ width: '90%' }}>
        <Autocomplete 
          style={{ width: '100%' }}
          disablePortal
          value={channelValue}
          id="combo-box-demo"
          options={userChannel}
          noOptionsText="Escolher o canal"
          onChange={(
            event: React.ChangeEvent<{}>,
            newValue: any,
          ) => {
            setChannelValue(newValue);
          }}
          getOptionSelected={
            (option: any, value: any) => {
              return (
                option?.label === value?.label || option?.name === value?.name
              );
            }
          }
          getOptionLabel={(option: any) => {
            return option?.label;
          }}
          renderInput={(params) => <TextField {...params} variant='outlined' label="Escolher o canal" />}
        />
      </div>
    );
  }

  return (
    <>
      <PlayerLayout>
        <>
          {shouldRenderGloboContent()}
          <S.StyledForm onSubmit={handleSubmit}>
            <CustomField
              options={handleConvertNameAndLabel(userLocation)}
              inputLabel="Escolher a praça"
              userValue={locationValue}
              setUserValue={setLocationValue}
              textValue={locationInputValue}
              setTextValue={setLocationInputValue}
              userRole={userData?.role}
              onSelect={async (props: any) => {
                //always get new channels when location changes
                await handleGetCanais(props?.name);
              }}
            />
            
            {handleRenderGloboAutocomplete()}

            <S.StyledPickersContainer utils={DateFnsUtils}>
              <S.StyledDatePicker
                id="date-picker-dialog"
                label="Escolher a data"
                invalidDateMessage="Data inválida"
                inputVariant="outlined"
                margin="normal"
                format="dd/MM/yyyy"
                value={inputDateAndTime}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disableFuture
                maxDateMessage="Data inválida"
              />
              <TimePicker
                startDate={inputStartTime}
                handleStartDate={setInputStartTime}
                endDate={inputEndTime}
                handleEndDate={setInputEndTime}
              />
            </S.StyledPickersContainer>
            <DefaultButton
              typecolor="blueButton"
              variant="contained"
              text="PESQUISAR"
              type="submit"
              disableRipple
              disabled={isDisabled || isLoading}
              styles={{ width: '90%' }}
            />
          </S.StyledForm>
        </>
        <>
          {isLoading ? (
            <S.StyledCircularProgress size="170px" />
          ) : (
            <Player
              src={src}
              IframeRef={IframeRef}
              inputStartTime={inputStartTime}
              inputDateAndTime={inputDateAndTime}
              finalMixedDate={mixDates(inputDateAndTime, inputStartTime)}
              timeStamp={timeStamp}
              location={{
                name: locationValue?.name,
                label: locationValue?.label,
              }}
              channel={{
                name: channelValue?.name,
                label: channelValue?.label,
                inputValue: channelInputValue,
              }}
            />
          )}
        </>
      </PlayerLayout>
      <Dialog
        openDialog={dialog.dialogStatus}
        setOpenDialog={dialog.setDialogStatus}
        dialogText={dialog?.dialogText}
        handleCloseLoading={setIsLoadingIframe}
        handleClearIframeSrc={setIframeSrc}
        />
    </>
  );
};

export default PlayerPage;
