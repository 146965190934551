import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDialog } from '../../contexts/dialog';

type DialogProps = {
  openDialog: boolean;
  // setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialog: (status: boolean) => void;
  dialogText?: string;
  handleCloseLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClearIframeSrc?: React.Dispatch<React.SetStateAction<any>>;
};

const SimpleDialog = ({
  dialogText,
  handleCloseLoading,
  handleClearIframeSrc,
}: DialogProps) => {
  const dialog = useDialog();

  const handleCloseDialog = () => {
    dialog.setDialogStatus(false);
  };

  const handleClearIframe = () => {
    dialog.setDialogStatus(false);
    handleClearIframeSrc!(null);
    handleCloseLoading!(false);
  }

  return (
    <div>
      <Dialog
        open={dialog.dialogStatus}
        onClose={handleClearIframe}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SimpleDialog;
