export const initialState = {
  name: '',
  showName: false,
  local: {
    selected: {
      name: '',
      label: ''
    },
    list: []
  },
  channel: {
    selected: {
      name: '',
      label: ''
    },
    list: []
  },
  enabled: "Sim",
  tune: 0,
  clipping_uri: 'CHANNEL0',
  live_uri: ''
}

export enum ActionTypes {
  'SET_NAME' = "SET_NAME",
  'SET_ENABLED' = "SET_ENABLED",
  'SET_LOCAL_LIST' = "SET_LOCAL_LIST",
  'SET_LOCAL_SELECTED' = "SET_LOCAL_SELECTED",
  'SET_CHANNEL_LIST' = 'SET_CHANNEL_LIST',
  'SET_CHANNEL_SELECTED' = 'SET_CHANNEL_SELECTED',
  'SET_TUNE' = 'SET_TUNE',
  'SET_CLIPPING_URI' = 'SET_CLIPPING_URI',
  'SET_LIVE_URI' = 'SET_LIVE_URI',
  'SET_SHOW_NAME' = 'SET_SHOW_NAME'
}

type ActionProps = {
  type: ActionTypes;
  payload?: any
}

export function GroupReducer (state = initialState, action: ActionProps) {
  switch (action.type) {
    case ActionTypes.SET_NAME:
      const { local, channel } = state;
      if (local && channel) {
        return { ...state, showName: true, name: `${local.selected?.name}-${channel.selected?.name}` }
      }
      return { ...state, showName: false, name: `${local.selected?.name}-${channel.selected?.name}` };
    case ActionTypes.SET_ENABLED:
      return { ...state, enabled: action.payload };
    case ActionTypes.SET_LOCAL_LIST:
      return { ...state, local: { ...state.local, list: action.payload } };
    case ActionTypes.SET_LOCAL_SELECTED:
      return { ...state, local: { ...state.local, selected: action.payload } };
    case ActionTypes.SET_CHANNEL_LIST:
      return { ...state, channel: { ...state.channel, list: action.payload } };
    case ActionTypes.SET_CHANNEL_SELECTED:
      return { ...state, channel: { ...state.channel, selected: action.payload } };
    case ActionTypes.SET_TUNE:
      return { ...state, tune: action.payload };
    case ActionTypes.SET_CLIPPING_URI:
      return { ...state, clipping_uri: `/players?source_name=${state.local.selected?.name}${state.channel.selected?.name}` };
    case ActionTypes.SET_LIVE_URI:
      return { ...state, live_uri: action.payload };
    default:
      return state;
  }
}