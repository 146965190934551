import { Box, Button, CircularProgress } from '@material-ui/core';
import styled, { css } from 'styled-components';

const IframeSize = css`
  width: 480px;
  height: 380px;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin: 100px 0px;
  ${IframeSize}
`;

export const StyledIframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`;

export const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const StyledDownloadButton = styled(Button)`
  && {
    position: absolute;
    right: 0;
    bottom: -56px;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 50px 0px;
`;