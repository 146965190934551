import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons/';

type InputProps = {
  id: string;
  name: string;
  label: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickShowPassword?: () => void;
  showPassword?: boolean;
  isPassword?: boolean;
  classes?: {};
};

const CustomInput = ({
  isPassword,
  id,
  name,
  label,
  value,
  showPassword,
  handleChange,
  handleClickShowPassword,
  classes,
}: InputProps) => {
  const handlePasswordSettings = () => {
    return (
      isPassword && (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {handleVisibility()}
          </IconButton>
        </InputAdornment>
      )
    );
  };

  const handleShowDetails = () => {
    if (isPassword) {
      return showPassword ? 'text' : 'password';
    } else {
      return 'text';
    }
  };

  const handleVisibility = () =>
    showPassword ? <Visibility /> : <VisibilityOff />;

  const handleFocus = () => (isPassword ? false : true);

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        name={name}
        required
        type={handleShowDetails()}
        value={value}
        onChange={handleChange}
        endAdornment={handlePasswordSettings()}
        labelWidth={70}
        autoFocus={handleFocus()}
        inputProps={{ 'aria-label': 'description' }}
        autoComplete='current-passowrd'
      />
    </FormControl>
  );
};

export default CustomInput;
