import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { customTheme } from '../../../container/styles';

const pixelToRem = (value: number) => {
  return `${customTheme.spacing(value)}px`;
}

type ContainerProps = { $isMobile: boolean }

export const Container = styled.div<ContainerProps>`
  display: ${props => props.$isMobile ? 'block' : 'flex'};

  nav {
    height: ${props => props.$isMobile ? 'auto' : ''}
  }
`;

export const SideBar = styled.nav`
  z-index: 10;
  position: sticky;
  top: 0;
  height: 100vh;
  color: ${props => props.theme.palette.primary.contrastText};
  background-color: ${props => props.theme.palette.primary.light};
`;

export const ListContainer = styled.div<ContainerProps>(({ theme, $isMobile }) => `
  height: 100%;

  > ul { 
    width: ${$isMobile ? '100%' : pixelToRem(31.25)};
    height: 100%;
    position: relative;
    
    li {
      justify-content: center;
      color: ${theme.palette.primary.contrastText};
    }

    li:last-child {
      position: ${$isMobile ? 'initial' : 'absolute'};
      bottom: 0;

      button {
        && {
          width: 95%;
        }
      }
    }
  }
`);

const selectBtnColor = (isBackBtn?: boolean) => {
  return isBackBtn ? 'blue' : 'rgb(23 43 96 / 4%)'
}

export const ListButton = styled(Button)<{ $currentPage?: boolean, $backButton?: boolean }>(({ $currentPage, $backButton }) => `
  color: #ccc;

  && {
    background-color: ${$currentPage ? 'rgb(23 43 96 / 19%)' : selectBtnColor($backButton)};
    color: ${$backButton ? '#fff' : 'initial'};
    width: 95%;
  
    :hover {
      background-color: rgb(23 43 96 / 19%);
    }
  }
`);