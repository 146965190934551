import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { UsersProps } from '../pages/admin/types';
import { retrieveFromStorage } from '../utils/local-storage-manager';

type UserProps = {
  user: UsersProps,
  setUser: React.Dispatch<React.SetStateAction<UsersProps>>
};

const UserContext = createContext({} as UserProps);

export const StoragedUserProvider = ({ children }: { children?: ReactNode }) => {
  const hasStoragedUser  = JSON.parse(retrieveFromStorage('lastEditedUser') as string);

  const [user, setUser] = useState<UsersProps>({
    username: hasStoragedUser?.username,
    email: hasStoragedUser?.email,
    name: hasStoragedUser?.name,
    enabled: hasStoragedUser?.enabled,
    password: 'teste@123',
    role: hasStoragedUser?.role,
    groups: hasStoragedUser?.groups,
    rendering: hasStoragedUser?.rendering
  })

  const finalUser = useMemo(
    () => ({
      user,
      setUser
    }),
    [user]
  );

  return (
    <UserContext.Provider value={finalUser}>{children}</UserContext.Provider>
  );
};

export const useStoragedUserInfo = () => useContext(UserContext);

export default StoragedUserProvider;
